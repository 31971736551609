import React, {useContext,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import {firebaseAuth} from '../../providers/AuthProvider';
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import Button from 'react-bootstrap/Button';

const Profile = () => {
  const navigate = useNavigate();
  const {user, handleSignout} = useContext(firebaseAuth)

useEffect(() => {
  if (!user || !user?.isAuthenticated) {    
    navigate('/manage/sign-in')
  }
}, [user]);
  const handleSignOut = (e) => {
    e.preventDefault()
    handleSignout()
  } 
  return (
    <div className="container">
          <div className="py-4"></div>
          <h3>{user?.email}</h3>
          <div className="py-3"></div>
          {user?.multiFactor && 
        <div><h5>Multi-Factor Authentication is enabled.</h5><p>Contact <a href="mailto:support@core12.com">support@core12.com</a> to have MFA configuration reset.</p></div>
}
{user && !user?.multiFactor &&
        <div><h5>Multi-Factor Authentication is not enabled.</h5><p>Click <a href="/manage/mfa">here</a> to enable MFA.</p></div>}
          <Button onClick={handleSignOut}><u>Logoff</u></Button>

    </div>
  );
};

export default Profile;