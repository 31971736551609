import React, { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from 'react-router-dom'
import { db } from "../../../firebase";
import { DeleteIcon, InfoIcon } from "../../../Constants";
import { firebaseAuth } from '../../../providers/AuthProvider';
import '../../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { CurrentEventDataContext } from "../CurrentEventDataContext";
import { toast } from "react-toastify";
const Sessions = () => {
  const [eventData, setEventData] = useState(null);
  const [eventReportData, setEventReportData] = useState(null);
  const [regData, setRegData] = useState(null);
  const { user } = useContext(firebaseAuth);
  const { id } = useParams();
  const { currentEventData, setCurrentEventData } = useContext(CurrentEventDataContext);
  const handleGalleryImageReadOLD = async (event, i) => {
    console.log(event.target.files);
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    for (let x = 0; x < files.length; x++) {
      console.log(files[x]);
      if (files[x].type !== "image/jpeg" && files[x].type !== "image/jpg" && files[x].type !== "image/png") {
        toast.error("Only jpeg and png files are allowed.");
        return;
      }
      const base64 = await convertBase64(files[x]);
      console.log('upating cloudinary');
      let j = i + x;
      console.log(eventData.eventGalleryImages);
      await uploadCloudinary(base64, eventData.id + "_eventGalleryImage_" + j, "/pcna/ezentrum/" + eventData.id + "/", "eventGalleryImage" + j, { crop: "fit", height: 900, width: 1600 }, 'eventGalleryImages', j);
    }
  };

  const handleGalleryImageRead = async (event, n, i) => {
    console.log(event.target.files);
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }
    for (let x = 0; x < files.length; x++) {
      console.log(files[x]);
      if (files[x].type !== "image/jpeg" && files[x].type !== "image/jpg" && files[x].type !== "image/png") {
        toast.error("Only jpeg and png files are allowed.");
        return;
      }
      const base64 = await convertBase64(files[x]);
      console.log('upating cloudinary');
      let j = i + x;
      
      await uploadCloudinary(base64, eventData.id + "_eventGalleryImage" + n + "_" + j, "/pcna/ezentrum/" + eventData.id + "/", "eventGalleryImage" + n + j, { crop: "fit", height: 900, width: 1600 }, 'eventGalleryImages' + n, j);
    }
  };
  
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadCloudinary = async (file, pubId, folder, key, transformation, list, i, field) => {
    // console.log(pubId); console.log(folder); console.log(key); console.log(transformation);
    // console.log(list); console.log(i); console.log(field);
    //uploadCloudinary(base64, eventData.id + "_" + x + "_" + i, "/pcna/ezentrum/" + eventData.id + "/", x + i, null, true, i);
    let uploadData = { file: file, public_id: pubId, folder: folder, transformation: transformation };
    var upload = firebase.functions().httpsCallable("app/uploadCloudinary");
    let result = await upload(uploadData);
    // upload(uploadData)
    //   .then((result) => {
    if (result?.data.message) {
      toast.warning(result?.data.message);
      return false;
    } else {
      toast.success("Image upload successful.");
      let tmpInputs = eventData;
      let u = result.data.url;
      //.split("/");
      // u.splice(6,1);
      // u = u.join("/");
      if (!list)
        tmpInputs[key] = u;
      let img = document.getElementById(key);
      if (img) img.src = img.src + "?v=" + Date.now(); // trick browser into reload
      if (!list) {
        setEventData({ ...tmpInputs });
        db.collection("events").doc(tmpInputs.id).set(tmpInputs);
        setEventData(tmpInputs);
        setCurrentEventData(tmpInputs);
        sessionStorage.setItem("eventData", JSON.stringify(tmpInputs));
        return true;
      } else {
        let tmpImages = eventData[list];
        if (!field) {
          tmpImages[i] = u;
          // console.log(tmpImages);
          setEventData({ ...eventData, tmpImages: tmpImages });
        } else {
          console.log(tmpImages);
          tmpImages[i][field] = u;
        }
        console.log('upating event');
        setEventData({ ...eventData, tmpImages: tmpImages });
        let tmpEventData = { ...eventData };
        tmpEventData[list] = tmpImages;
        db.collection("events").doc(tmpEventData.id).set(tmpEventData);
        setCurrentEventData(tmpEventData);
        sessionStorage.setItem("eventData", JSON.stringify(tmpEventData));
        return true;
      }
    }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   toast.error(error.message);
    //   return false;
    // });
  }
  const removeCloudinary = (pubId, key, disableNotifications) => {
    var remove = firebase.functions().httpsCallable("app/removeCloudinary");
    remove(pubId)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        } else {
          if (!disableNotifications) toast.success("Image removal successful.");
          let tmpInputs = eventData;
          if (key != null && key >= 0) {
            tmpInputs[key] = "";
            let img = document.getElementById(key);
            // remove element?
            setEventData(tmpInputs);
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);
            setEventData(tmpInputs);
            setCurrentEventData(tmpInputs);
            sessionStorage.setItem("eventData", JSON.stringify(tmpInputs));
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!disableNotifications) toast.error(error.message);
      });
  }
  const loadEventData = async () => {
    let tmpEventData = eventData;
    if (!tmpEventData || tmpEventData.id !== id) {
      tmpEventData = (await db.collection("events").doc(id).get()).data();
      setEventData(tmpEventData);
      setCurrentEventData(tmpEventData);
      sessionStorage.setItem("eventData", JSON.stringify(tmpEventData));
    }
  };
  useEffect(() => {
    loadEventData();
  }, []);
  useEffect(() => {
  }, [eventData]);
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>{eventData?.sysname ? eventData?.sysname : eventData?.name} - Event Gallery</h1>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 1
        </label>
        <input
          type="text"
          id="eventGalleryLabel1"
          name="eventGalleryLabel1"
          value={eventData?.eventGalleryLabel1 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel1 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel1: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages1;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages1: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages1?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 1 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName1" + index}
                    type="file"
                    multiple
                    name="originalFileName1"
                    onChange={(e) => handleGalleryImageRead(e, 1, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage1" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage1_" + index, index);
                  let eventGalleryImages1 = eventData.eventGalleryImages1;
                  eventGalleryImages1.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages1: eventGalleryImages1 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 2
        </label>
        <input
          type="text"
          id="eventGalleryLabel2"
          name="eventGalleryLabel2"
          value={eventData?.eventGalleryLabel2 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel2 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel2: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages2;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages2: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages2?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 2 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName2" + index}
                    type="file"
                    multiple
                    name="originalFileName2"
                    onChange={(e) => handleGalleryImageRead(e,2, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage2" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage2_" + index, index);
                  let eventGalleryImages2 = eventData.eventGalleryImages2;
                  eventGalleryImages2.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages2: eventGalleryImages2 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 3
        </label>
        <input
          type="text"
          id="eventGalleryLabel3"
          name="eventGalleryLabel3"
          value={eventData?.eventGalleryLabel3 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel3 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel3: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages3;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages3: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages3?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 3 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName3" + index}
                    type="file"
                    multiple
                    name="originalFileName3"
                    onChange={(e) => handleGalleryImageRead(e, 3, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage3" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage3_" + index, index);
                  let eventGalleryImages3 = eventData.eventGalleryImages3;
                  eventGalleryImages3.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages3: eventGalleryImages3 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 4
        </label>
        <input
          type="text"
          id="eventGalleryLabel4"
          name="eventGalleryLabel4"
          value={eventData?.eventGalleryLabel4 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel4 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel4: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages4;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages4: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages4?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 4 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName4" + index}
                    type="file"
                    multiple
                    name="originalFileName4"
                    onChange={(e) => handleGalleryImageRead(e,4,index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage4" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage4_" + index, index);
                  let eventGalleryImages4 = eventData.eventGalleryImages4;
                  eventGalleryImages4.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages4: eventGalleryImages4 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 5
        </label>
        <input
          type="text"
          id="eventGalleryLabel5"
          name="eventGalleryLabel5"
          value={eventData?.eventGalleryLabel5 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel5 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel5: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages5;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages5: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages5?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 5 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName5" + index}
                    type="file"
                    multiple
                    name="originalFileName5"
                    onChange={(e) => handleGalleryImageRead(e, 5, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage5" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage5_" + index, index);
                  let eventGalleryImages5 = eventData.eventGalleryImages5;
                  eventGalleryImages5.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages5: eventGalleryImages5 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 6
        </label>
        <input
          type="text"
          id="eventGalleryLabel6"
          name="eventGalleryLabel6"
          value={eventData?.eventGalleryLabel6 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel6 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel6: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages6;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages6: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages6?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 6 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName6" + index}
                    type="file"
                    multiple
                    name="originalFileName6"
                    onChange={(e) => handleGalleryImageRead(e,6, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage6" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage6_" + index, index);
                  let eventGalleryImages6 = eventData.eventGalleryImages6;
                  eventGalleryImages6.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages6: eventGalleryImages6 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 7
        </label>
        <input
          type="text"
          id="eventGalleryLabel7"
          name="eventGalleryLabel7"
          value={eventData?.eventGalleryLabel7 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel7 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel7: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages7;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages7: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages7?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 7 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName7" + index}
                    type="file"
                    multiple
                    name="originalFileName7"
                    onChange={(e) => handleGalleryImageRead(e, 7,index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage7" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage7_" + index, index);
                  let eventGalleryImages7 = eventData.eventGalleryImages7;
                  eventGalleryImages7.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages7: eventGalleryImages7 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="py-3"></div>
      <div className="form-group">
        <label className="form-label form-help">
          Event Gallery Label 8
        </label>
        <input
          type="text"
          id="eventGalleryLabel8"
          name="eventGalleryLabel8"
          value={eventData?.eventGalleryLabel8 || ''}
          onChange={(e) => {
            let tmpEventData = { ...eventData };
            tmpEventData.eventGalleryLabel8 = e.target.value;
            setEventData({
              ...eventData,
              eventGalleryLabel8: e.target.value,
            });
            db.collection("events").doc(tmpEventData.id).set(tmpEventData);
          }}
          className="form-control"
        />
      </div>
      <div className="text-end">
        <button
          className={"btn btn-theme"}
          onClick={() => {
            let tmpGallery = eventData.eventGalleryImages8;
            if (!tmpGallery) tmpGallery = [];
            tmpGallery.push("");
            setEventData({
              ...eventData,
              eventGalleryImages8: tmpGallery,
            });
          }}
        >
          + Image
        </button>
      </div>
      <div className="py-2"></div>
      <div className="row">
        {eventData?.eventGalleryImages8?.map((img, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-6">
            <div className="gallery-image-container">
              <div className="form-group">
                <label className="form-label">Event Gallery Image 8 (Most images should be 16:9 aspect ratio).</label>
                <div>
                  <input
                    id={"originalFileName8" + index}
                    type="file"
                    multiple
                    name="originalFileName8"
                    onChange={(e) => handleGalleryImageRead(e,8, index)}
                  />
                </div>
              </div>
              <div className={img ? "" : "d-none"}>
                <img id={"eventGalleryImage8" + index}
                  className="gallery-image w-100"
                  src={img}
                />
              </div>
              <div className="text-end">
                <i className="del-icon ml-2" onClick={(e) => {
                  removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_eventGalleryImage8_" + index, index);
                  let eventGalleryImages8 = eventData.eventGalleryImages8;
                  eventGalleryImages8.splice(index, 1);
                  setEventData({ ...eventData, eventGalleryImages8: eventGalleryImages8 });
                }}>
                  <DeleteIcon />
                </i>
              </div>
            </div>
          </div>
        ))}
      </div>

    
    </div>
  );
};
export default Sessions;
