import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import "firebase/storage";
import { db } from "../../firebase";
import "firebase/functions";
import {
  DeleteIcon,
  CheckIcon,
  DLAbbrDesMap,
  DownloadIcon,
} from "../../Constants";
import { toast } from "react-toastify";
import moment from "moment";
import { PCNANavbar } from "../main/PCNANavbar";
import { saveAs } from "file-saver";
import CsvDownloadButton from "react-json-to-csv";
const Registrations = () => {
  const storage = firebase.storage().ref();
  const history = useNavigate();
  const defaultFilter = JSON.parse(localStorage.getItem("regFilters"));
  const [regFilters, setRegFilters] = useState(defaultFilter ? defaultFilter : {
    search: "",
    ccType: "",
    leadSent: "",
  });
  const [allSubmissions, setAllSubmissions] = useState([]);
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });
  const [data, setData] = useReducer(reducer, {
    registrations: [],
    isFetching: true,
    message: null,
  });
  const filter = () => {
    let registrations = data.registrations;
    if (!registrations || registrations.length == 0) {
      console.log('fetch3');
      fetch();
      return;
    }
    let filteregReg = [];
    registrations.map((reg, i) => {
      reg.idx = i;
      if (regFilters && !regFilters.ccType && !regFilters.leadSent && !regFilters.search) {
        filteregReg.push(reg);
      } else {
        let searchCheck = !regFilters?.search;
        let ccTypeCheck = !regFilters?.ccType;
        let leadSentCheck = !regFilters?.leadSent;
        if (!searchCheck) {
          if (
            reg.bpid
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1
          ) {
            searchCheck = true;
          }
          if (
            reg.dealer
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1
          ) {
            searchCheck = true;
          }
          if (
            reg.vin
              ?.toUpperCase()
              .indexOf(regFilters?.search?.toUpperCase()) > -1
          ) {
            searchCheck = true;
          }
        }
        if (!leadSentCheck) {
          if (reg.leadSent && regFilters.leadSent === "Yes") {
            leadSentCheck = true;
          }
          if (!reg.leadSent && regFilters.leadSent === "No") {
            leadSentCheck = true;
          }
        }
        if (!ccTypeCheck) {
          if (
            reg.customercare_type
              ?.toUpperCase() === regFilters?.ccType?.toUpperCase()
          ) {
            ccTypeCheck = true;
          }
        }
        if (searchCheck && leadSentCheck && ccTypeCheck) filteregReg.push(reg);
      }
    });
    setData({ ...data, filtered: filteregReg, filter: false });
  };
  const fetch = () => {
    let tmpItems = [];
      db.collection("submissions")
        .orderBy("createdAt", "desc")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let tmpItem = doc.data();
            tmpItem.id = doc.id;
            if (tmpItem.createdAt && !tmpItem.createdDate)
              try {
                if (tmpItem.createdAt.seconds) {
                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.createdDate = moment((tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  // alert('ehllo2');
                  tmpItem.createdDate = moment(tmpItem.createdAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }
              } catch {
              }
            if (tmpItem.updatedAt && !tmpItem.updatedDate)
              try {
                if (tmpItem.updatedAt.seconds) {
                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.updatedDate = moment((tmpItem.updatedAt.seconds * 1000 + tmpItem.updatedAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  tmpItem.updatedDate = moment(tmpItem.updatedAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }
              } catch {
              }
              tmpItems.push(tmpItem);
          });
          setData({
            registrations: tmpItems,
            isFetching: false,
            message: null,
            filtered: null,
            filter: true,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          setData({
            tmpItems: data.registrations,
            isFetching: false,
            filtered: null,
            filter: true,
            message: null,
          });
        });
    
  }
  const loadInit = async () => {

    console.log('fetch1');
    fetch();
  };
  useEffect(() => {
    loadInit();
    // DOMPurify.setConfig({ ADD_ATTR: ['target'] });
  }, []);
  useEffect(() => {
    if (!data.isFetching && data.filter) {
      filter();
    }
  }, [data]);
  useEffect(() => {
    localStorage.setItem("regFilters", JSON.stringify(regFilters));
    if (!data.isFetching) filter();
  }, [regFilters]);
  
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading registrations.</div>;
    } else {
      return null;
    }
  }
  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }
  const downloadSubmissions = async (filtered) => {
    let tmpAllSubmissions = [];
    let tmpData = filtered ? data.filtered : data.registrations;
    tmpData.map(async (reg, i) => {
      let tmpSubmission = {};
      // tmpSubmission["id"] = reg["id"];
      // if (eventData?.dataFields["company"]?.visible)
      //   tmpSubmission["company"] = reg["company"];
      // if (eventData?.dataFields["title"]?.visible)
      //   tmpSubmission["title"] = reg["title"];
      // if (eventData?.dataFields["lastName"]?.visible)
      //   tmpSubmission["lastName"] = reg["lastName"];
      // if (eventData?.dataFields["firstName"]?.visible)
      //   tmpSubmission["firstName"] = reg["firstName"];
      // if (eventData?.dataFields["email"]?.visible)
      //   tmpSubmission["email"] = reg["email"];
      // if (eventData?.dataFields["phone"]?.visible)
      //   tmpSubmission["phone"] = reg["phone"];
      // if (eventData?.dataFields["address1"]?.visible)
      //   tmpSubmission["address1"] = reg["address1"];
      // if (eventData?.dataFields["address2"]?.visible)
      //   tmpSubmission["address2"] = reg["address2"];
      // if (eventData?.dataFields["city"]?.visible)
      //   tmpSubmission["city"] = reg["city"];
      // if (eventData?.dataFields["state"]?.visible)
      //   tmpSubmission["state"] = reg["state"];
      // if (eventData?.dataFields["postal"]?.visible)
      //   tmpSubmission["postal"] = reg["postal"];
      // if (eventData?.allowPushToCap) {
      //   tmpSubmission["dealer_optin"] = reg["dealer_optin"] ? "true" : "false";
      //   tmpSubmission["leadSent"] = reg["leadSent"] ? "true" : "false";
      // }
      // if (eventData?.dataFields["dealer"]?.visible)
      //   tmpSubmission["dealer"] = reg["dealer"];
      // if (eventData?.dataFields["experience"]?.visible)
      //   tmpSubmission["experience"] = reg["experience"];
      // if (eventData?.dataFields["modelInterest"]?.visible)
      //   tmpSubmission["modelInterest"] = reg["modelInterest"];
      // if (eventData?.dataFields["purchaseTimeFrame"]?.visible)
      //   tmpSubmission["purchaseTimeFrame"] = reg["purchaseTimeFrame"];
      // let showPayment = false;
      // for (const key2 in eventData?.additionalFields) {
      //   if (eventData?.additionalFields[key2].fieldType === "CommerceItem")
      //     showPayment = true;
      //   let tmpVal = reg[eventData?.additionalFields[key2].fieldName];
      //   if (typeof tmpVal === 'string' || tmpVal instanceof String) {
      //     tmpVal = tmpVal.replaceAll('"', '');
      //     tmpVal = tmpVal.replaceAll(',', '');
      //   }
      //   if (eventData?.additionalFields[key2].fieldName) {
      //     tmpSubmission[eventData?.additionalFields[key2].fieldName] = tmpVal;
      //     if (eventData?.additionalFields[key2].fieldType === "CommerceItem")
      //       tmpSubmission[eventData?.additionalFields[key2].fieldName + "Cost"] = reg[eventData?.additionalFields[key2].fieldName + "Cost"];
      //   }
      // }
      // let tmpSubmission = {
      //   id: reg.id,
      //   firstName: reg.firstName,
      //   lastName: reg.lastName,
      //   email: reg.email,
      //   phone: reg.phone,
      //   status: reg.status,
      // };
      tmpSubmission["status"] = reg["status"];
      try {
        tmpSubmission["createdAt"] = reg["createdAt"] ? moment(reg["createdAt"].toDate()).format("MM/DD/YYYY h:mm:ss A") : "";
      } catch {
        tmpSubmission["createdAt"] = "N/A";
      }
      try {
        tmpSubmission["updatedAt"] = reg["updatedAt"] ? moment(reg["updatedAt"].toDate()).format("MM/DD/YYYY h:mm:ss A") : "";
      } catch {
        tmpSubmission["updatedAt"] = "N/A";
      }
      tmpAllSubmissions.push(tmpSubmission);
      setAllSubmissions(tmpAllSubmissions);
    });
  };
  const clearLeadData = async () => {
    if (user.role === 1) {
      alert(
        "Clearing processed lead indicator.  This may take several minutes.  Do not restart until you see the success message."
      );
      data.registrations.map((reg, i) => {
        if (reg.leadSent) {
          reg.leadSent = false;
          db.collection("submissions")
            .doc(reg.id)
            .update({ leadSent: false });
        }
      });
      alert(
        "Success. Leads will be resent during nightly lead processing job."
      );
    } else {
      alert("Only an admin can delete all registrations.");
    }
  };
  useEffect(() => {
    checkAuth();
  }, [user]);
  const checkAuth = () => {
    if (!user.isAuthenticated) {
      history("/manage/sign-in");
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="container">
      <h1>Customer Care Submissions</h1>
      <div>
        <label>Submissions 90 days:</label>{" "}
        <span className="fs-md">
          {data.filtered?.length ? data.filtered?.length : 0} of {data.registrations?.length}
        </span>
      </div>
      <div className="py-2"></div>
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="form-group">
            <label>&nbsp;</label>
            <input
              type="text"
              className="form-control"
              name="Search"
              value={regFilters?.search}
              onChange={(e) => {
                setRegFilters({ ...regFilters, search: e.target.value });
              }}
              placeholder="Search BP Id, Dealer, or VIN."
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label>CC Type</label>
            <select
              className="form-control"
              name="CCTypeSearch"
              value={regFilters?.ccType}
              onChange={(e) => {
                setRegFilters({ ...regFilters, ccType: e.target.value });
              }}
            >
              <option value=""></option>
              <option value="address-update">Address Update</option>
              <option value="email-update">Email Update</option>
              <option value="email-optin">Email Optin</option>
              <option value="mobile-number-update">Mobile Number Update</option>
              <option value="ownership">Ownership</option>
              <option value="non-owner">Non-Owner</option>
              <option value="christophorus-extend">Christophorus Extend</option>
              <option value="christophorus-cancel">Christophorus Cancel</option>

            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Lead Sent</label>
            <select
              className="form-control"
              name="LeadSentSearch"
              value={regFilters?.leadSent}
              onChange={(e) => {
                setRegFilters({ ...regFilters, leadSent: e.target.value });
              }}
            >
              <option value=""></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className="col-1">
          <button
            className="btn btn-default"
            onClick={() => {
              setRegFilters({ search: "", ccType: "", leadSent: ""});
            }}
          >
            CLEAR
          </button>
        </div>

      </div>
      <div className="py-2"></div>
      <Fetching />
      <Message />
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th className="d-none d-md-table-cell">CC Type</th>
              <th className="d-none d-md-table-cell">BP Id</th>
              <th className="d-none d-md-table-cell">Dealer</th>
              <th className="d-none d-md-table-cell">VIN</th>
              <th className="d-table-cell">Email</th>
              <th className="d-table-cell">Opt-In</th>
              <th className="d-none d-md-table-cell">Mobile Phone</th>
              
              <th className="d-none d-md-table-cell">Address</th>
              <th className="d-none d-md-table-cell">Vehicle Status</th>
              <th className="d-none d-md-table-cell">Vehicle Date</th>
              <th className="d-none d-md-table-cell">Created</th>
              <th className="d-none d-md-table-cell">Sent to C@P</th>
            </tr>
          </thead>
          <tbody>
            {data?.filtered?.map((r, i) => (
              <tr
                key={i}
                className="my-2 pointer"
              >
                <td className="d-none d-md-table-cell">
                  {r.customercare_type}
                </td>
                <td className="d-none d-md-table-cell">
                  {r.bpid}
                </td>
                <td className="d-table-cell">
                  {r.dealer}
                </td>
                <td className="d-table-cell">
                  {r.vin}
                </td>
                <td className="d-table-cell">
                  {r.email}
                </td>
                <td className="d-table-cell">
                  {r.email_optin}
                </td>
                <td className="d-none d-md-table-cell">
                  {r.mobile_phone}
                </td>
                <td className="d-none d-md-table-cell">
                  {r.address1}{r.address2 ? ", " + r.address2 : ""}<br/>{r.city} {r.state} {r.postal}
                </td>
                <td className="d-none d-md-table-cell">
                  {r.vehicle_status}                   
                </td>
                <td className="d-none d-md-table-cell">
                  {r.vehicle_status_month}<br/>{r.vehicle_status_year}
                </td>
                <td className="d-none d-md-table-cell">
                  {r.createdDate}
                </td>
          
                <td className="d-none d-md-table-cell">
                  {r.leadSent ? "Yes" : "No"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(user.role === 1 || user.role === 2) && (
        <div>
          <div className="py-5"></div>
          <div className="row">
            <div className="col">
              <div>
                <button
                  className={
                    allSubmissions.length > 0 ? "d-none" : "btn btn-link"
                  }
                  onClick={() =>
                    window.confirm(
                      "Are you sure you want to download all data, this may take a few minutes?"
                    )
                      ? downloadSubmissions(false)
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DownloadIcon />
                  </i>{" "}
                  All Data
                </button>
              </div>
            </div>
            <div className="col">
              <div>
                <button
                  className={
                    allSubmissions.length > 0 ? "d-none" : "btn btn-link"
                  }
                  onClick={() =>
                    window.confirm(
                      "Are you sure you want to download all data, this may take a few minutes?"
                    )
                      ? downloadSubmissions(true)
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DownloadIcon />
                  </i>{" "}
                  Fitlered Data
                </button>
                <div className={allSubmissions.length > 0 ? "" : "d-none"}>
                  <CsvDownloadButton target="_blank" delimiter="," data={allSubmissions} className="btn btn-link" />
                </div>
              </div>
            </div>
            {user.role === 1 &&
              <div className="col text-end">
                <button
                  className="btn btn-link"
                  onClick={() =>
                    window.confirm("Are you sure to resend lead data?")
                      ? clearLeadData()
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <CheckIcon />
                  </i>{" "}
                  Resend Lead Data
                </button>
              </div>
            }
            {/* {user.role === 1 &&
              <div className="col text-end">
                <button
                  className="btn btn-link"
                  onClick={() =>
                    window.confirm("Are you sure to delete ALL registrations?")
                      ? deleteAllRegistrations()
                      : console.log("action cancelled by user.")
                  }
                >
                  <i className="sm">
                    <DeleteIcon />
                  </i>{" "}
                  All Registrations
                </button>
              </div>
            } */}
          </div>
        </div>
      )}
    </div>
  );
};
export default Registrations;
