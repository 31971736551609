import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useReducer } from "react";
import { Navigate, Routes } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider';
import '../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../Constants";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";

const CampaignCodes = () => {
  
  const reducer = (state, payload) => ({ ...state, ...payload });

  const { user } = useContext(firebaseAuth);
    const [data, setData] = useReducer(reducer, {
      registrations: [],
      isFetching: true,
      message: null,
    });
  const loadInit = async () => {
    let tmpItems = [];
      db.collection("campaigncodes")
        .orderBy("eventDate", "desc")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let tmpItem = doc.data();
            tmpItem.id = doc.id;
            if (tmpItem.createdAt && !tmpItem.createdDate)
              try {
                if (tmpItem.createdAt.seconds) {
                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.createdDate = moment((tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  // alert('ehllo2');
                  tmpItem.createdDate = moment(tmpItem.createdAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }
              } catch {
              }
            if (tmpItem.updatedAt && !tmpItem.updatedDate)
              try {
                if (tmpItem.updatedAt.seconds) {
                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.updatedDate = moment((tmpItem.updatedAt.seconds * 1000 + tmpItem.updatedAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  tmpItem.updatedDate = moment(tmpItem.updatedAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }
              } catch {
              }
              tmpItems.push(tmpItem);
          });
          console.log("tmpItems", tmpItems);
          setData({
            registrations: tmpItems,
            isFetching: false,
            message: null,
            filtered: null,
            filter: true,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          setData({
            tmpItems: data.registrations,
            isFetching: false,
            filtered: null,
            filter: true,
            message: null,
          });
        });
    
  }
  useEffect(() => {
    loadInit();
  }, []);
const history = useNavigate();
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Campaign Codes</h1>
    
      <div className="row pb-5">
      <div className="col-md text-end">
          <button
            onClick={() => history("/manage/campaign-code-requests")}
            className="btn btn-theme"
          >
            Add Campaign Code
          </button>
        </div>
      
      </div>
      <div className="table-responsive">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Program</th>
            <th>Event Name</th>
            <th>Event Date</th>
            <th>Lead Campaign Code</th>
            {/* <th>Lead Campaign Description</th> */}
            <th>IH Campaign Code</th>
            {/* <th>IH Campaign Description</th> */}
            {/* <th>Requestor</th> */}
            <th>Request Date</th>
            <th>CRM Approved</th>
          </tr>
        </thead>
        <tbody>
        {data?.registrations?.map((r, i) => (
              <tr
                key={i}
                className={r.approved ? "green-alert my-2 pointer" : "my-2 pointer"}
                onClick={() => history("/manage/campaign-code-requests/" + r.id)}
              >
                <td className="d-table-cell">
                  {r.program}
                </td>
                <td className="d-table-cell">
                  {r.eventName}
                </td>
                <td className="d-table-cell">
                  {r.eventDate} {r.eventEndDate ? " - " + r.eventEndDate : ""}
                </td>
                <td className="d-table-cell">
                  {r.leadCampaignCode}
                </td>
                {/* <td className="d-table-cell">
                  {r.leadCampaignDescription}
                </td> */}
                <td className="d-table-cell">
                  {r.ihCampaignCode}
                </td>
                {/* <td className="d-table-cell">
                  {r.ihCampaignDescription}
                </td> */}
                {/* <td className="d-table-cell">
                  {r.requestor && r.requestor.length > 0 ? r.requestor[0].name : "N/A"}
                </td> */}
                <td className="d-table-cell"> 
                  {r.createdDate ? r.createdDate : "N/A"}
                  </td>
                <td className="d-table-cell">
                  {r.approved ? "Approved" : "Pending"}
                </td>
                </tr>
        ))}

        </tbody>
      </table>
    </div>
    </div>
  );
};
export default CampaignCodes;