import React, { useContext, useEffect, useState } from "react";
import { Navigate, Routes, useParams,useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider';
import '../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { db } from "../../firebase";
import { GetDeviceId } from "../../Constants";
import moment from "moment";
import { toast } from "react-toastify";

import { Multiselect } from "multiselect-react-dropdown";
const CampaignCodeRequests = () => {
  const { id } = useParams();
    const history = useNavigate();
  
  const { user } = useContext(firebaseAuth);
  const [editCC, setEditCC] = useState(null);
  const multiSelectStyle = { chips: { background: "#D5001C", color: '#FFFFFF' } };
  const [userList, setUserList] = useState([]);
  const userListRef = React.createRef();
  const [adminList, setAdminList] = useState([]);
  const adminListRef = React.createRef();
  useEffect(() => {
    
    var getUserList = firebase.functions().httpsCallable("app/userList");
    getUserList().then(async function (result) {
      setUserList(result.data);
    });
    var getAdminList = firebase.functions().httpsCallable("app/adminList");
    getAdminList().then(async function (result) {
      setAdminList(result.data);
    });
    if (!id) {
      let tmpId = db.collection("campaigncodes").doc().id;
      let tmpCC = { id: tmpId };
      setEditCC(tmpCC);
    } else {
      db.collection("campaigncodes").doc(id).get()
        .then(async (result) => {
          let tmpResult = result.data();
                      if (tmpResult.createdAt)
                        try {
                          if (tmpResult.createdAt.seconds) {
                            // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                            tmpResult.createdDate = moment((tmpResult.createdAt.seconds * 1000 + tmpResult.createdAt.nanoseconds / 1000000)).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          } else {
                            // alert('ehllo2');
                            tmpResult.createdDate = moment(tmpResult.createdAt).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          }
                        } catch {
                        }
                      if (tmpResult.updatedAt)
                        try {
                          if (tmpResult.updatedAt.seconds) {
                            // tmpResult.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                            tmpResult.updatedDate = moment((tmpResult.updatedAt.seconds * 1000 + tmpResult.updatedAt.nanoseconds / 1000000)).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          } else {
                            tmpResult.updatedDate = moment(tmpResult.updatedAt).format(
                              "MM/DD/YYYY h:mm A"
                            );
                          }
                        } catch {
                        }
          if (tmpResult.expiration?.seconds) {
            tmpResult.expiration = tmpResult.expiration.seconds * 1000 + tmpResult.expiration.nanoseconds / 1000000
          }
          setEditCC(tmpResult);
        })
        .catch((e) => {
          // Sentry.captureException(e);
        });
    }
  }, []);
  const saveCC = async () => {
    let ts = firebase.firestore.Timestamp.fromDate(new Date());
    let tmpCC = editCC;
    if (!tmpCC?.createdAt)
      tmpCC.createdAt = ts;
    tmpCC.updatedAt = ts;
    if (!tmpCC?.expiration)
      tmpCC.expiration = ts;
    //validation
    if(!tmpCC.eventDate) {
      alert("Event Date is required");
      return;
    }
    var saveCC = firebase.functions().httpsCallable("app/saveCC");
    let result = await saveCC(tmpCC);
    if (result.data.error) {
      alert(result.data.message);
      return;
    }
        toast.success("Saved");
    
  }
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Campaign Code Request</h1>
      <div className="py-3"></div>
      <div className="row">
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="program">
              Program *
            </label>
            <input
              type="text"
              id="program"
              name="program"
              disabled={editCC?.approved}
              value={editCC?.program || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="eventName">
              Event Name *
            </label>
            <input
              type="text"
              id="eventName"
              name="eventName"
              disabled={editCC?.approved}
              value={editCC?.eventName || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="eventDate">
              Event Start Date *
            </label>
            <input
              type="date"
              id="eventDate"
              name="eventDate"
              disabled={editCC?.approved}
              value={editCC?.eventDate || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="eventEndDate">
              Event End Date
            </label>
            <input
              type="date"
              id="eventEndDate"
              name="eventEndDate"
              disabled={editCC?.approved}
              value={editCC?.eventEndDate || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="leadCampaignCode">
              Lead Campaign Code *
            </label>
            <input
              type="text"
              id="leadCampaignCode"
              name="leadCampaignCode"
              disabled={editCC?.approved}
              value={editCC?.leadCampaignCode || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="leadCampaignDescription">
              Lead Campaign Description *
            </label>
            <input
              type="text"
              id="leadCampaignDescription"
              name="leadCampaignDescription"
              value={editCC?.leadCampaignDescription || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              disabled={editCC?.approved}
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="ihCampaignCode">
              IH Campaign Code *
            </label>
            <input
              type="text"
              id="ihCampaignCode"
              name="ihCampaignCode"
              value={editCC?.ihCampaignCode || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              disabled={editCC?.approved}
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label" htmlFor="ihCampaignDescription">
              IH Campaign Description *
            </label>
            <input
              type="text"
              id="ihCampaignDescription"
              name="ihCampaignDescription"
              value={editCC?.ihCampaignDescription || ""}
              onChange={(e) => setEditCC({ ...editCC, [e.target.name]: e.target.value })}
              className="form-control"
              disabled={editCC?.approved}
              required={true}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label">Requestors *</label>
            <Multiselect
              options={userList}
              style={multiSelectStyle}
              displayValue="name"
              ref={userListRef}
              selectedValues={editCC?.requestor}
              closeIcon="close"
              disable={editCC?.approved}
              onSelect={(e) => {
                setEditCC({
                  ...editCC,
                  requestor: userListRef.current.getSelectedItems(),  
                });
              }}
              onRemove={() => {
                setEditCC({
                  ...editCC,
                  requestor: userListRef.current.getSelectedItems(),
                });
              }}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="form-group">
            <label className="form-label">Approvers *</label>
            <Multiselect
              options={adminList}
              style={multiSelectStyle}
              displayValue="name"
              ref={adminListRef}
              disable={editCC?.approved}
              selectedValues={editCC?.approver}
              closeIcon="close"
              onSelect={() => {
                setEditCC({
                  ...editCC,
                  approver: adminListRef.current.getSelectedItems(),
                });
              }}
              onRemove={() => {
                setEditCC({
                  ...editCC,
                  approver: adminListRef.current.getSelectedItems(),
                });
              }}
            />
          </div>

        </div>
      </div>
      <div className={"form-group pt-3"}>
            <label className="checkbox-container">
              CRM Approved
              <input
                type="checkbox"
                onChange={(e) => {
                  setEditCC({
                    ...editCC,
                    approved: e.target.checked,
                  });
                }}
                id="approved"
                name="approved"
                checked={editCC?.approved}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div class="pt-3">
            {editCC?.approved && 
          <button className="btn btn-theme" onClick={() => {
            var sendRequesterEmail = firebase.functions().httpsCallable("app/sendRequesterEmail");
            sendRequesterEmail(editCC);
            toast.success("Email Sent");
          }}>
            Send Requester Email
          </button>
}
{!editCC?.approved && 
          <button className="btn btn-theme" onClick={() =>{
            var sendApproverEmail = firebase.functions().httpsCallable("app/sendApproverEmail");
            sendApproverEmail(editCC);
            toast.success("Email Sent");
          }}>
 Send Approver Email
          </button>
}
          </div>
          <div className="pt-2">
            Created: {editCC?.createdDate}
          </div>
          <div className="pb-3">
            Updated: {editCC?.updatedDate}
          </div>
      <div className="row">
        <div className="col-auto">
          <button className="btn btn-theme" onClick={saveCC}>
            Save
          </button>
        </div>
        <div className="col-auto">

          <button
            onClick={() => history("/manage/campaign-codes")}
            className="btn btn-theme"
          >
            Close
          </button>
          </div>
          <div className="col"></div>
          <div className="col-auto">

<button
  onClick={async () => {
    if (window.confirm("Are you sure you want to delete this campaign code?") == true) {
      
    
    var deleteCC = firebase.functions().httpsCallable("app/deleteCC");
    let result = await deleteCC(id);
    if (result.data.error) {
      alert(result.data.message);
      return;
    }
    history("/manage/campaign-codes");
  }
  }}
  className="btn btn-theme"
>
  Delete
</button>
</div>
      </div>
      
    </div>
  );
};
export default CampaignCodeRequests;