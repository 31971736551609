import React, {useState, useEffect} from 'react';
import {authMethods} from './AuthMethods'
import firebase from '../firebase'

export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
  const initState = {email: '', password: ''}
  const [inputs, setInputs] = useState(initState)
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{});


  const handleSignup = () => {
    authMethods.signup(inputs.email, inputs.password,setErrors ,setUser )
    console.log(errors, user);
  }
  const handleSignin = () => {
    authMethods.signin(inputs.email, inputs.password, setErrors, setUser)
      }

      
  const handleSignout = () => {
    localStorage.removeItem('user');
    setUser(null);
    authMethods.signout(setErrors, setUser)
  }
  useEffect(() => {
    setUser(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{}); 
    firebase.auth().onAuthStateChanged(userAuth => {
      let emailVeriried = false;
      let multiFactor = false;
      if(userAuth) {
        if(userAuth.multiFactor?.user?.emailVerified) {
          emailVeriried = true;
        } 
        if(userAuth.multiFactor?.enrolledFactors[0]?.factorId === 'phone') {
          multiFactor = true;
        }
       
        // if(emailVeriried && !multiFactor) {
      userAuth.getIdTokenResult(true) 
        .then((idTokenResult) => {
          if(idTokenResult) {
            // if(multiFactor) {
            let u = { isAuthenticated: true, email:idTokenResult.claims.email,user: userAuth, role: idTokenResult.claims.role, token: idTokenResult.token, multiFactor: multiFactor, emailVerified: emailVeriried };
            localStorage.setItem('user',JSON.stringify(u));
            setUser(u);
            // } else {
            //   let u = { isAuthenticated: true, email:idTokenResult.claims.email,user: userAuth, role: null, token: null, multiFactor: multiFactor, emailVerified: emailVeriried,};
            //   localStorage.setItem('user',JSON.stringify(u));
            //   setUser(u);
            // }
          } 
        });
      // }
    }
    });
    // firebase.auth().onIdTokenChanged(userAuth => {
    //   if(userAuth) {
    //   userAuth.getIdTokenResult(true) 
    //     .then((idTokenResult) => {          
    //       if(idTokenResult) {
    //         let u = { isAuthenticated: true, email:idTokenResult.claims.email,user: idTokenResult.user, role: idTokenResult.claims.role, token: idTokenResult.token };
    //         localStorage.setItem('user',JSON.stringify(u));
    //         setUser(u);
    //       }
    //     });
    //   }
    // });
    
  },[]);
  return (
    <firebaseAuth.Provider
    value={{
      //replaced test with handleSignup
      handleSignup,
      handleSignin,
      user,
      inputs,
      setInputs,
      errors,
      setErrors,
      handleSignout,
    }}>
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;