import React, { useEffect, useContext, useState, useReducer, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import {
  multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator,
  RecaptchaVerifier, getAuth
} from "firebase/auth";
// import * as Sentry from "@sentry/react";
const MFA = () => {
  const navigate = useNavigate();
  const { errors, user,handleSignout } = useContext(firebaseAuth)
  const [mfaComplete, setMFAComplete] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");

  const auth = getAuth();
  useEffect(() => {
    console.log(user);
  }, []);

  
  useEffect(() => {
    if (!user || !user?.isAuthenticated) {    
      navigate('/manage/sign-in')
    }
  }, [user]);
    const handleSignOut = (e) => {
      e.preventDefault()
      handleSignout()
    } 
  const handleSetup = (e) => {
    e.preventDefault();
    if(!validPhone) 
    {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    const recaptchaVerifier = new RecaptchaVerifier(auth, "setup-button", {
      "size": "invisible",
      "callback": function (response) {
        console.log('recaptcha solved', response);
      }
    });
    multiFactor(user.user).getSession()
      .then(function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      }).then(function (verificationId) {
        // Ask user for the verification code. Then:
        setVerificationId(verificationId);
      })
      .catch(function (error) {
        toast.error("There was an error setting up multi-factor authentication.  Please logout of eZentrum and try again.");
      });
  }
  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    // Ask user for the SMS verification code. Then:
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    await multiFactor(user.user).enroll(multiFactorAssertion, "phone");
    setMFAComplete(true);
    setTimeout(() => {
      // handleSignout();
    },3000);
  }
  return (
    <div className="container" id="mfa">
      {(mfaComplete || user?.multiFactor) &&
        <div><h5>Multi-Factor Authentication is enabled.</h5><p>Contact <a href="mailto:support@core12.com">support@core12.com</a> to have MFA configuration reset.</p></div>      }
      {!mfaComplete && !user?.multiFactor && <>
        {!verificationId &&

          <Form onSubmit={handleSetup}>
            <h3 className="pb-5">Multi-Factor Autentication SMS Setup</h3>
            <FormGroup className="auth-input" controlId="email">
              <Form.Label>Enter 10-digit mobile number to receive SMS messages to verify your login.<br/><br/> (Required by PCNA IT Security requirements.)</Form.Label>
              <Form.Control type="text" id="vphone" onChange={(e) => { 
                let tmpPhone = e.target.value;
                
                tmpPhone = tmpPhone.replace(/\D/g, '');
                
                                if(tmpPhone.length === 10 || (tmpPhone.length === 11 && tmpPhone[0] === "1")) {
                  tmpPhone = "+1 " + tmpPhone.substring(0,3) + "-" + tmpPhone.substring(3,6) + "-" + tmpPhone.substring(6,10);
                  setValidPhone(true);
                  e.target.disabled = true;
                } else {
                  tmpPhone = tmpPhone.replace("+1", "");
                  setValidPhone(false);
                }
                console.log(tmpPhone);
                setPhoneNumber(tmpPhone); 
                e.target.value = tmpPhone;
                }} />
            </FormGroup>

            <div className="py-3"></div>
            <button type="submit" className="btn btn-dark" id="setup-button"><span>Submit</span></button>
{validPhone &&            
            <div className="py-1">
            <Button type="button" onClick={(e) => {
              e.preventDefault();
              let tmpInput = document.getElementById("vphone");
              tmpInput.disabled = false;
              tmpInput.value = "";
              setPhoneNumber("");
              setValidPhone(false);
            }}><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Reset Form</span></Button>

            </div>
}<div className={"py-1"}>
<Button onClick={handleSignOut}><u>Logoff</u></Button>

</div>
            <Spacer size="2" />
            {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
          </Form>


        }
        {verificationId &&

          <Form onSubmit={handleCodeSubmit}>
            <h3 className="pb-5">Multi-Factor Autentication SMS Setup</h3>
            <FormGroup className="auth-input" controlId="email">
              <Form.Label>Please enter the code from an SMS message sent to your registered device.</Form.Label>
              <Form.Control type="text" onChange={(e) => { setVerificationCode(e.target.value) }} />
            </FormGroup>

            <div className="py-3"></div>
            <button type="submit" className="btn btn-dark"><span>Submit</span></button>
            <div className="py-1"></div>

            <Spacer size="2" />
            {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
          </Form>

        }
      </>
      }
    </div>
  );
};
export default MFA;
