import React, { useRef } from 'react';
import JoditEditor from "jodit-react";



export const VisualEditor = React.memo(({ idkey, value, changeItem }) => {
  const config = {
    minHeight: 400,
    maxWidth: 800,
    readonly: false,
    toolbar: true
    // all options from https://xdsoft.net/jodit/doc/

  }
  const copyEditor = useRef(null);
  // console.log(idkey);
  var keys = idkey?.split('_');

  // console.log(value);
  if (idkey) {
    return (

      <JoditEditor
        ref={copyEditor}
        value={keys?.length > 1 ? value[keys[0]][keys[1]][keys[2]] : value[idkey]}
        config={config}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => {value[idkey] = newContent;changeItem(idkey,value)}}
        onBlur={newContent => {
          if (keys?.length > 1) {
            value[keys[0]][keys[1]][keys[2]] = newContent;
            changeItem(idkey, value)
          } else {
            value[idkey] = newContent;
            changeItem(idkey, value)
          }
        }}

        onChange={newContent => { //console.log(idkey);
        }}
      />
    );
  } else {
    return null;
  }
});
export default VisualEditor;