import React, { useRef, useState, useContext, useCallback, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import moment from "moment";
import { firebaseAuth } from "../../../providers/AuthProvider";
import { NavLink, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import { toast } from "react-toastify";
import { DeleteIcon, InfoIcon } from "../../../Constants";
import DOMPurify from "dompurify";
import { Modal } from "react-bootstrap";
import VisualEditor from "./VisualEditor";
import { CurrentEventDataContext } from "../CurrentEventDataContext";

// import * as Sentry from "@sentry/react";
const style = {
  border: "1px dashed gray",
  padding: "0",
  marginBottom: "0",
  backgroundColor: "white",
  cursor: "move",
};
export const EventCTAItem = ({ id, item, index, moveCard, updateData, eventData, setEventData }) => {
  // const [ eventData, setEventData ] = useState(tmpEventData);
  const { currentEventData, setCurrentEventData } = useContext(CurrentEventDataContext);
  const { user } = useContext(firebaseAuth);
  const ref = useRef(null);
  const history = useNavigate();
  const [editCopy, setEditCopy] = useState(null)
  const [help, setHelp] = useState({});
  const removeCloudinary = (pubId, key, disableNotifications) => {
    var remove = firebase.functions().httpsCallable("app/removeCloudinary");
    remove(pubId)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };
  const handleChangeItem = useCallback((idkey, value) => {
    // console.log(idkey,value);
    // let tmpInputs = {...eventData};
    // tmpInputs[idkey] = value;
    // setEventData(tmpInputs);
    // // console.log(tmpInputs);
    // setEventData({...eventData,[idkey]:value});
    setEventData(value);
  }, []);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      console.log("dragging");
      console.log(id);
      console.log(index);
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  // const copyEvent = (evt) => {
  //   let tmpEvent = { ...evt };
  //   tmpEvent.name = "Copy of " + tmpEvent.name;
  //   tmpEvent.publicUrl = tmpEvent.publicUrl + "_copy";
  //   tmpEvent.startDate = null;
  //   tmpEvent.endDate = null;
  //   tmpEvent.campaignCode = "";
  //   tmpEvent.campaignCodeVerified = false;
  //   tmpEvent.id = db.collection("events").doc().id;
  //   //copy banner image, mobile and featuerd
  //   //copy carousel when finished.
  //   setCurrentEventData(tmpEvent);
  //   setEventData(tmpEvent);
  //   sessionStorage.setItem("eventData", JSON.stringify(tmpEvent));
  //   history("/manage/event");
  //   // Sentry.captureMessage(
  //   //   "Event " + evt.id + " copied to " + tmpEvent.id + "successfully.",
  //   //   "info"
  //   // );

  //   toast.success("Event copied successfully.");
  // };

  const archiveEvent = (evt) => {
    evt.status = "Archived";
    db.collection("events")
      .doc(evt.id)
      .set(evt);
    toast.success("Event archived successfully.");
    updateData();
  };
  const restoreEvent = (evt) => {
    evt.status = "Active";
    db.collection("events")
      .doc(evt.id)
      .set(evt);
    toast.success("Event restored successfully.");
    updateData();
  };
  drag(drop(ref));



  const handleListImageRead = async (event, i, x, y) => {
    const file = event.target.files[0];

    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData.id + "_" + x + "_" + i, "/pcna/ezentrum/" + eventData.id + "/", x + i, null, x, i, y);

  };
  const fieldList = ['id', 'eventId', 'company', 'title', 'firstName', 'lastName', 'email', 'phone', 'address1', 'address2', 'city', 'state', 'postal', 'country', 'experience', 'purchaseTimeFrame', 'dealer', 'modelInterest', 'adyenResultCode', 'adyenRefusalReason', 'adyenRef', 'adyenRefDate', 'adyenLink', 'adyenLinkId', 'adyenLinkExpire', 'status', 'adyenPayemntStatus', 'hasWaiver', 'createdAt', 'updatedAt', 'leadSent'];
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleListChange = async (event, i, x, checkbox) => {
    let tmpList = eventData[x];
    console.log(tmpList);
    if (event.target.name === 'fieldName');
    if (fieldList.indexOf(event.target.value) >= 0) {
      toast.error('Invalid field name, field already in use.');
      return;
    }

    tmpList[i] = { ...tmpList[i], [event.target.name]: checkbox ? event.target.checked : event.target.value };
    console.log(tmpList);
    setEventData({ ...eventData, tmpList: tmpList });
  };
  const uploadCloudinary = (file, pubId, folder, key, transformation, list, i, field) => {
    //uploadCloudinary(base64, eventData.id + "_" + x + "_" + i, "/pcna/ezentrum/" + eventData.id + "/", x + i, null, true, i);

    let uploadData = { file: file, public_id: pubId, folder: folder, transformation: transformation };
    var upload = firebase.functions().httpsCallable("app/uploadCloudinary");
    upload(uploadData)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        } else {
          toast.success("Image upload successful.");
          let tmpInputs = eventData;
          let u = result.data.url;
          console.log(u);
          console.log(list);
          console.log(key);
          console.log('======')
          //.split("/");
          // u.splice(6,1);
          // u = u.join("/");
          if (!list)
            tmpInputs[key] = u;
          let img = document.getElementById(key);
          console.log(img);
          console.log(key);
          img.src = img.src + "?v=" + Date.now(); // trick browser into reload
          if (!list) {
            setEventData({ ...tmpInputs });
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);

            setEventData(tmpInputs);
            sessionStorage.setItem("eventData", JSON.stringify(tmpInputs));

            setCurrentEventData(tmpInputs);
          } else {
            let tmpImages = eventData[list];
            if (!field) {
              tmpImages[i] = u;
              console.log(tmpImages);
              setEventData({ ...eventData, tmpImages: tmpImages });
            } else {
              console.log(tmpImages);
              tmpImages[i][field] = u;
            }
            setEventData({ ...eventData, tmpImages: tmpImages });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  }
  // useEffect(() => {
  //   let tmpEventData = JSON.parse(sessionStorage.getItem('eventData'));
  //   if(tmpEventData)
  //     setEventData(tmpEventData);
  //   // let tmpEventData = currentEventData;
  //   setEventData(tmpEventData);
  // }, []);
  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      id={"card-" + index}
      data-handler-id={handlerId}
      className={index % 2 == 0 ? "alt-row cta-list-item" : "cta-list-item"}
    >
      <div className="semi-bold-font"><h2>CTA #{index + 1}</h2></div>

      {eventData?.ctaLayout != "OnlyButtons" ? (
        <>
          {eventData?.ctaLayout != "FullRows" ? (
            <div>
              <div className="form-group">
                <label className="form-label">CTA Image (800x600)</label>
                <div>
                  <input
                    id={"originalFileName" + index}
                    type="file"
                    name="originalFileName"
                    onChange={(e) => handleListImageRead(e, index, 'ctaList', 'ctaImage')}
                  />
                </div>
              </div>
              <div className={item.ctaImage ? "banner-image mobile-banner" : "d-none"}>
                <img id={"ctaList" + index}
                  className=""
                  src={item.ctaImage}
                />
              </div>
            </div>
          ) : (<div></div>)}
          {eventData?.ctaLayout === "SpacedTiles" ? (
            <>
              <div className="form-group">
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, ctaTitle: !help.ctaTitle });
                  }}
                >
                  Subtitle 1
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.name ? "fs-xs" : "d-none"}>
                  Subtitle 1
                </div>
                <input
                  type="text"
                  id={"ctaSubtitle1"}
                  name="ctaSubtitle1"
                  value={eventData?.ctaList[index].ctaSubtitle1 || ''}
                  onChange={(e) => { handleListChange(e, index, 'ctaList') }}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, ctaTitle: !help.ctaTitle });
                  }}
                >
                  Subtitle 2
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.name ? "fs-xs" : "d-none"}>
                  Subtitle 2
                </div>
                <input
                  type="text"
                  id={"ctaSubtitle2"}
                  name="ctaSubtitle2"
                  value={eventData?.ctaList[index].ctaSubtitle2 || ''}
                  onChange={(e) => { handleListChange(e, index, 'ctaList') }}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, ctaTitle: !help.ctaTitle });
                  }}
                >
                  Subtitle 3
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.name ? "fs-xs" : "d-none"}>
                  Subtitle 3
                </div>
                <input
                  type="text"
                  id={"ctaSubtitle3"}
                  name="ctaSubtitle3"
                  value={eventData?.ctaList[index].ctaSubtitle3 || ''}
                  onChange={(e) => { handleListChange(e, index, 'ctaList') }}
                  className="form-control"
                />
              </div>
            </>)
            : (<div></div>)}
          <div className="form-group">
            <label
              className="form-label form-help"
              onClick={() => {
                setHelp({ ...help, ctaTitle: !help.ctaTitle });
              }}
            >
              Title{" "}
              <i className="xs">
                <InfoIcon />
              </i>
            </label>
            <div className={help.name ? "fs-xs" : "d-none"}>
              Title
            </div>
            <input
              type="text"
              id={"ctaTitle" + index}
              name="ctaTitle"
              value={eventData?.ctaList[index].ctaTitle || ''}
              onChange={(e) => { handleListChange(e, index, 'ctaList') }}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label
              className="form-label form-help"
              onClick={() => {
                setHelp({ ...help, ctaDescription: !help.ctaDescription });
              }}
            >
              Description{" "}
              <i className="xs">
                <InfoIcon />
              </i>
            </label>
            <div className={help.name ? "fs-xs" : "d-none"}>
              Description.
            </div>
            {/* <textarea
              id={"ctaDescription"}
              name="ctaDescription"
              value={eventData?.ctaList[index].ctaDescription || ''}
              onChange={(e) => { handleListChange(e, index, 'ctaList') }}
              className="form-control"
            /> */}
            <div className="copy-container">
              <div className={eventData?.overviewCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    eventData?.ctaList[index].ctaDescription),
                }}
              ></div>
              <button className="btn btn-default" onClick={() => { setEditCopy("ctaList_" + index + "_ctaDescription"); }}>Edit Description</button>
            </div>
          </div>

        </>
      ) : (<div></div>)
      }
      {/* {eventData?.ctaLayout != "FullTiles" ? ( */}
      <div className="form-group">
        <label
          className="form-label form-help"
          onClick={() => {
            setHelp({ ...help, ctaLabel: !help.ctaLabel });
          }}
        >

          Button 1 Label{" "}
          <i className="xs">
            <InfoIcon />
          </i>
        </label>
        <div className={help.ctaLabel ? "fs-xs" : "d-none"}>
          Button 1 Label.
        </div>
        <input
          type="text"
          id={"ctaLabel" + index}
          name="ctaLabel"
          value={eventData?.ctaList[index].ctaLabel || ''}
          onChange={(e) => { handleListChange(e, index, 'ctaList') }}
          className="form-control"
        />
      </div>
      {/* ) : (<div></div>)} */}
      <div className="form-group">
        <label
          className="form-label form-help"
          onClick={() => {
            setHelp({ ...help, ctaLink: !help.ctaLink });
          }}
        >
          Button 1 Link{" "}
          <i className="xs">
            <InfoIcon />
          </i>
        </label>
        <div className={help.ctaLink ? "fs-xs" : "d-none"}>
          Link for Button 1.
        </div>
        <input
          type="text"
          id={"ctaLink" + index}
          name="ctaLink"
          value={eventData?.ctaList[index].ctaLink || ''}
          // onChange={(e) => { alert('hello'); handleListChange(e, index, 'ctaList') }}
          onChange={(e) => { handleListChange(e, index, 'ctaList') }}
          className="form-control"
        />
      </div>
      <div className={"form-group"}>
        <label className="checkbox-container">
          Button 1 New Window?
          <input
            type="checkbox"
            onChange={(e) => { handleListChange(e, index, 'ctaList', true) }}
            id={"ctaExternal" + index}
            name="ctaExternal"
            checked={eventData?.ctaList[index].ctaExternal || false}
          />
          <span className="checkmark"></span>
        </label>
      </div>
      <div>
        <div className={"form-group"}>
          <label className="checkbox-container">
            Button 1 Include &gt; Icon?
            <input
              type="checkbox"
              onChange={(e) => { handleListChange(e, index, 'ctaList', true) }}
              id={"ctaIcon" + index}
              name="ctaIcon"
              checked={eventData?.ctaList[index].ctaIcon || false}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div className="form-group">
        <label
          className="form-label form-help"
          onClick={() => {
            setHelp({ ...help, ctaLabel2: !help.ctaLabel2 });
          }}
        >

          Button 2 Label{" "}
          <i className="xs">
            <InfoIcon />
          </i>
        </label>
        <div className={help.name ? "fs-xs" : "d-none"}>
          Button 2 Label.
        </div>
        <input
          type="text"
          id={"ctaLabel2" + index}
          name="ctaLabel2"
          value={eventData?.ctaList[index].ctaLabel2 || ''}
          onChange={(e) => { handleListChange(e, index, 'ctaList') }}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label
          className="form-label form-help"
          onClick={() => {
            setHelp({ ...help, ctaLink2: !help.ctaLink2 });
          }}
        >
          Button 2 Link{" "}
          <i className="xs">
            <InfoIcon />
          </i>
        </label>
        <div className={help.name ? "fs-xs" : "d-none"}>
          Link for Button 2.
        </div>
        <input
          type="text"
          id={"ctaLink2" + index}
          name="ctaLink2"
          value={eventData?.ctaList[index].ctaLink2 || ''}
          // onChange={(e) => { alert('hello'); handleListChange(e, index, 'ctaList') }}
          onChange={(e) => { handleListChange(e, index, 'ctaList') }}
          className="form-control"
        />
      </div>
      <div className={"form-group"}>
        <label className="checkbox-container">
          Button 2 New Window?
          <input
            type="checkbox"
            onChange={(e) => { handleListChange(e, index, 'ctaList', true) }}
            id={"ctaExternal2" + index}
            name="ctaExternal2"
            checked={eventData?.ctaList[index].ctaExternal2 || false}
          />
          <span className="checkmark"></span>
        </label>
      </div>
      <div>
        <div className={"form-group"}>
          <label className="checkbox-container">
            Button 2 Include &gt; Icon?
            <input
              type="checkbox"
              onChange={(e) => { handleListChange(e, index, 'ctaList', true) }}
              id={"ctaIcon2" + index}
              name="ctaIcon"
              checked={eventData?.ctaList[index].ctaIcon2 || false}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
<hr/>
      <div className={"form-group"}>
          <label className="checkbox-container">
            Content Centered?
            <input
              type="checkbox"
              onChange={(e) => { handleListChange(e, index, 'ctaList', true) }}
              id={"centered" + index}
              name="centered"
              checked={eventData?.ctaList[index].centered || false}
            />
            <span className="checkmark"></span>
          </label>
        </div>

      <div className="text-end">
        <i className="del-icon ml-2" onClick={(e) => {
          // removeCloudinary("pcna/ezentrum/" + eventData.id + "/" + eventData.id + "_ctaList_" + index, null);
          let ctaList = eventData.ctaList;
          ctaList.splice(index, 1);
          setEventData({ ...eventData, ctaList: ctaList });
        }}>
          <DeleteIcon />
        </i>
      </div>
      <Modal enforceFocus={false} show={editCopy == null ? false : true} onHide={() => { setEditCopy(null) }}>
        <Modal.Header closeButton>
          <Modal.Title>Visual Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fieldList.length > 0 && editCopy?.toLowerCase().indexOf('email') >= 0 &&
            <div>
              <label>Available Fields:</label><br />
              {fieldList.map((g, index) => (
                <span>{g}{index < fieldList.length - 1 ? ", " : ""}</span>
              ))}
              <br /><br />
            </div>
          }
          <VisualEditor idkey={editCopy} value={eventData} changeItem={handleChangeItem} />

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-theme" onClick={() => { setEditCopy(null); }}>Update</button>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div >
  );
};
