
import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useReducer } from "react";
import { Navigate, Routes } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider';
import '../../assets/css/reports.css';
import firebase from "firebase/compat/app";
import { GetDeviceId } from "../../Constants";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";

const LeadActivity = () => {
  
  const reducer = (state, payload) => ({ ...state, ...payload });

  const { user } = useContext(firebaseAuth);
    const [data, setData] = useReducer(reducer, {
      registrations: [],
      isFetching: true,
      message: null,
    });
  const loadInit = async () => {
    let tmpItems = [];
      db.collection("leads")
        .orderBy("createdAt", "desc")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let tmpItem = doc.data();
            tmpItem.id = doc.id;
            console.log(tmpItem);
            if (tmpItem.createdAt && !tmpItem.createdDate)
              try {
                if (tmpItem.createdAt.seconds) {
                  // tmpItem.createdAt = tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000;
                  tmpItem.createdDate = moment((tmpItem.createdAt.seconds * 1000 + tmpItem.createdAt.nanoseconds / 1000000)).format(
                    "MM/DD/YYYY h:mm A"
                  );
                } else {
                  // alert('ehllo2');
                  tmpItem.createdDate = moment(tmpItem.createdAt).format(
                    "MM/DD/YYYY h:mm A"
                  );
                }
              } catch {
              }
 
              tmpItems.push(tmpItem);
          });
          setData({
            registrations: tmpItems,
            isFetching: false,
            message: null,
            filtered: null,
            filter: true,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          setData({
            tmpItems: data.registrations,
            isFetching: false,
            filtered: null,
            filter: true,
            message: null,
          });
        });
    
  }
  useEffect(() => {
    loadInit();
  }, []);
const history = useNavigate();
  if (!user.isAuthenticated)
    return <Navigate to="/manage/sign-in" />
  return (
    <div className='container'>
      <h1>Lead Activity</h1>
    
<div className="py-3"></div>
      <div className="table-responsive">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Date Sent</th>
            <th>Data Type</th>
            <th>Campaign Code</th>
            <th>Campaign Description</th>
            <th>Filename</th>
            <th>Records</th>
          </tr>
        </thead>
        <tbody>
        {data?.registrations?.map((r, i) => (
              <tr
                key={i}
                className="my-2 pointer"
              >
                <td className="d-table-cell">
                  {r.createdDate}
                </td>
                <td className="d-table-cell">
                  {r.dataType}
                </td>
                <td className="d-table-cell">
                  {r.campaignCode}
                </td>
                <td className="d-table-cell">
                  {r.campaignDescription}
                </td>
                <td className="d-table-cell">
                  {r.filename}
                </td>
                <td className="d-table-cell">
                  {r.records}
                </td>
                </tr>
        ))}

        </tbody>
      </table>
    </div>
    </div>
  );
};
export default LeadActivity;