import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../providers/AuthProvider'
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import { ArrowHeadRightIcon, Spacer } from '../../Constants';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { toast } from "react-toastify";
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword
} from "firebase/auth";
const SignIn = (props) => {
  const { inputs, setInputs, errors, setErrors, user } = useContext(firebaseAuth)
  const [redirect, setRedirect] = useState(false);
  const [redirectMFA, setRedirectMFA] = useState(false);
    const [resolver, setResolver] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  let useQuery = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    if (user?.isAuthenticated && user?.multiFactor)
      setRedirect(true);
    else if(user?.isAuthenticated)
      setRedirectMFA(true);
  }, [user]);
  useEffect(() => {

    let pw_update = useQuery.get('pw_update');
    if (pw_update)
      toast.success('Password set successfully.  Please login below.');
    setInputs({ email: '', password: '', pw_update: pw_update ? true : false });
    setErrors([]);
  }, []);
  if (redirect)
    navigate('/manage');
  if (redirectMFA)
    navigate('/manage'); // navigate('/manage/mfa');
  const handleSubmit = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, inputs.email, inputs.password)
      .then((data) => {
        db.collection("invites")
          .where("email", "==", inputs.email)
          .get()
          .then(function (snapshot) {
            snapshot.docs.forEach(d => {
              db.collection("invites")
                .doc(d.id)
                .delete();
            });
          });
      })
      .catch(async (error) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          let tmpResolver = getMultiFactorResolver(auth, error);
          setResolver(tmpResolver);

          // Ask user which second factor to use.
          console.log(tmpResolver);
          if (tmpResolver.hints[0].factorId ===
            "phone") {
            const phoneInfoOptions = {
              multiFactorHint: tmpResolver.hints[0],
              session: tmpResolver.session
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            // Send SMS verification code
            let tmpRecaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
              "size": "invisible",
              "callback": function (response) {
                // reCAPTCHA solved, you can proceed with
                // phoneAuthProvider.verifyPhoneNumber(...).
                // onSolvedRecaptcha();
                // alert('recaptcha solved');
        
                console.log('recaptcha solved', response);
              }
            });
            setRecaptchaVerifier(tmpRecaptchaVerifier);
            phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, tmpRecaptchaVerifier)
              .then(function (verificationId) {
                setVerificationId(verificationId);

              })
              .catch(function (error) {
                console.error("Error sending SMS verification", error);
              });
          } else {
            toast.error("MFA not setup correclty.");
            console.error("Error with MFA setup.", error);
          }
        } else {
          toast.error("Username or password are incorrect.");
          console.log("Error signing in with password and email", error);
        }
      });
  }
  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    // Ask user for the SMS verification code. Then:
    console.log('multiFactorAssertion');
    const cred = PhoneAuthProvider.credential(
      verificationId, verificationCode);
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(cred);
    console.log(multiFactorAssertion);
    try {
    let idTokenResult = await resolver.resolveSignIn(multiFactorAssertion);

    console.log(idTokenResult);
    // let u = { isAuthenticated: true, email:idTokenResult.claims.email,user: idTokenResult.user, role: idTokenResult.claims.role, token: idTokenResult.token };
    // localStorage.setItem('user',JSON.stringify(u));
    // setUser(u);
    } catch (error) {
      console.error("Error signing in with MFA", error);
    }
  }
  const handleChange = e => {
    const { id, value } = e.target
    setInputs(prev => ({ ...prev, [id]: value }))
  }
  return (
    <div className="container">
      <Spacer size="2" />
      <h1>Sign-In</h1>
      <Spacer size="1" />
      {!verificationId &&
        <Form onSubmit={handleSubmit}>
          <FormGroup className="auth-input" controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="email" onChange={handleChange} />
          </FormGroup>
          <FormGroup className="auth-input" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" onChange={handleChange} autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false" />
          </FormGroup>
          <div className="py-3"></div>
          <Button type="submit" id="sign-in-button"><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Login</span></Button>
          <div className="py-1"></div>
          <div className="row">
            <div className="col-auto">
              <Link to="/manage/forgot-password">Forgot Password?</Link>
            </div>
          </div>
          <Spacer size="2" />
          {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
        </Form>
      }
      {verificationId &&
        <Form onSubmit={handleCodeSubmit}>
          <FormGroup className="auth-input" controlId="email">
            <Form.Label>Please enter the code from an SMS message sent to your registered device.</Form.Label>
            <Form.Control type="text" onChange={(e) => { setVerificationCode(e.target.value) }} />
          </FormGroup>

          <div className="py-3"></div>
          <Button type="submit" ><i className="light"><ArrowHeadRightIcon fill="#FFFFFF" /></i> <span>Submit</span></Button>
          <div className="py-1"></div>

          <Spacer size="2" />
          {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
        </Form>

      }
    </div>
  );
};
export default SignIn;
// import { useEffect } from "react";
// // imported from firebase auth sdk
// import { getAuth } from "firebase/auth";
// // ensures compatibility with the older versions of firebase
// import firebase from "firebase/compat/app";
// // imports pre-built UI for firebase authentication
// import * as firebaseui from "firebaseui";
// // imports the firebaseui styles using the CDN
// import "firebaseui/dist/firebaseui.css";
// // import { app } from "./firebase";
// export default function SignIn() {
//   useEffect(() => {
//     const ui =
//       firebaseui.auth.AuthUI.getInstance() ||
//       // since Firebase v9 and above service are imported when needed instad of being a namespace
//       new firebaseui.auth.AuthUI(getAuth());
//     ui.start("#firebaseui-auth-container", {
//       signInSuccessUrl: "/manage",
//       signInFlow: "popup",
//       signInOptions: [
//         // Leave the lines as is for the providers you want to offer your users.
//         // {
//         //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//         //   clientId:
//         //     "1006672558365-p6jpr3b7r76kng93j6mrirh9pua5k2at.apps.googleusercontent.com",
//         // },
//         {
//           provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//         },
//         // leave for ANOTHER video
//         // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
//       ],
//       // required to enable one-tap sign-up credential helper
//       credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
//     });
//   }, []);
//   return <div id="firebaseui-auth-container"></div>;
// }