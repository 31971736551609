import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
// import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, InfoIcon } from "../../../Constants";
import "../../../assets/css/groups.css";
// import moment from "moment";
import { toast } from "react-toastify";
import "../../../assets/css/event.css";
import QRCode from "qrcode.react";
import { Multiselect } from "multiselect-react-dropdown";
import DOMPurify from "dompurify";
import firebase from "firebase/compat/app";
import VisualEditor from "./VisualEditor";
import moment from "moment-timezone";
import { nextUntil } from "dom-helpers";
import { Modal } from "react-bootstrap";
import { EventCTAItem } from './EventCTAItem';
import { setUser } from "@sentry/react";
import Select from 'react-select';
import { CurrentEventDataContext } from "../CurrentEventDataContext";
import { JsonEditor } from 'json-edit-react'
// import { writeJSON } from "fs-extra";
const Event = () => {
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });
  const [help, setHelp] = useState({});
  const [floatingSave, setFloatingSave] = useState(false);
  const [endDateChange, setEndDateChange] = useState(false);
  const [settings, setSettings] = useState({
    mailboxes: [],
    waiverDefaultCopy: [],
    optInDefaultCopy: [],
  });
  const [eventData, setEventData] = useState(null);
  const { currentEventData, setCurrentEventData } = useContext(CurrentEventDataContext);
  const [campaignCodes, setCampaignCodes] = useState([]);
  const [importPasscode, setImportPasscode] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [showModules, setShowModules] = useState([]);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [models, setModels] = useState([]);
  const multiSelectStyle = { chips: { background: "#D5001C" } };
  const dealerSelectRef = React.createRef();
  const userListRef = React.createRef();
  const modelSelectRef = React.createRef();
  const optInSelectRef = React.createRef();
  const waiverSelectRef = React.createRef();
  const [eventFull, setEventFull] = useState(true);
  const [editCopy, setEditCopy] = useState(null)
  const [userList, setUserList] = useState([]);
  // const [passcodeUserRefs, setPasscodeUserRefs] = useState([]);
  const [sessionData, setSessionData] = useState(null);
  const history = useNavigate();
  const alertRules = ["IncompleteWaiver", "IncompletePayment", "IsGuest", "Pending", "NotCheckedIn", "CheckedIn", "Completed", "Waitlist"];
  const [showOptIn, setShowOptIn] = useState(false);
  const [showWaiver, setShowWaiver] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const { id } = useParams();
  const loadInit = async () => {
    let storageEventData = sessionStorage.getItem('eventData');
    // console.log(storageEventData);
    if (storageEventData && (storageEventData == '' || storageEventData == 'undefined')) {
      history("/manage");
      return;
    }
    let tmpEventData = null; //JSON.parse(storageEventData);
    // console.log(tmpEventData);
    if (tmpEventData && tmpEventData !== '')
      setEventData(tmpEventData);
    // let tmpEventData = currentEventData
    if (!tmpEventData || tmpEventData?.id !== id) {
      if (id) {
        let tmpUpdateEventData = await db.collection("events").doc(id).get();
        tmpEventData = tmpUpdateEventData.data();
      } else {
        let tmpEventId = await db.collection("events").doc().id;
        const newEvent = {
          id: tmpEventId, name: 'New Event', email: 'info', status: 'Active', sortOrder: 0, usersAllowed: [{ name: user.email }],
          dataFields: {
            dealer: { visible: false, required: false }, experience: { visible: true, required: false }, purchaseTimeFrame: { visible: true, required: false }, firstName: { visible: true, required: true }, title: { visible: false, required: false },
            address1: { visible: false, required: false }, city: { required: false, visible: false }, dealer: { visible: false }, email: { visible: true, required: true }, lastName: { visible: true, required: true },
            modelInterest: { visible: false }, phone: { visible: false }, postal: { required: true, visible: true }, purchaseTimeFrame: { visible: true }, state: { required: false, visible: false }
          }
        }
        tmpEventData = newEvent;
      }
    }
    if (tmpEventData) {
      setEventData(tmpEventData);
      setCurrentEventData(tmpEventData);
      sessionStorage.setItem('eventData', JSON.stringify(tmpEventData));
    } else {
      toast.error("Select to edit or view registrations for an event before continuing..");
      history("/manage");
      return;
    }
    let regInfo = await firebase.functions().httpsCallable("app/regCount");
    let result = await regInfo({ id: eventData?.id });
    if (!result.data.error && tmpEventData)
      tmpEventData.regCount = result.data;

    if (!tmpEventData.dataFields) tmpEventData.dataFields = [];
    if (tmpEventData.createdAt && tmpEventData.createdAt.seconds) {
      tmpEventData.createdAt = new Date(tmpEventData.createdAt.seconds * 1000 + tmpEventData.createdAt.nanoseconds / 1000000)
    } else if (tmpEventData.createdAt && tmpEventData.createdAt._seconds) {
      tmpEventData.createdAt = new Date(tmpEventData.createdAt._seconds * 1000 + tmpEventData.createdAt._nanoseconds / 1000000)
    } else if (tmpEventData.createdAt) {
      tmpEventData.createdAt = new Date(tmpEventData.createdAt)
    }
    if (tmpEventData.updatedAt && tmpEventData.updatedAt.seconds)
      tmpEventData.updatedAt = new Date(tmpEventData.updatedAt.seconds * 1000 + tmpEventData.updatedAt.nanoseconds / 1000000)
    else if (tmpEventData.updatedAt && tmpEventData.updatedAt._seconds)
      tmpEventData.updatedAt = new Date(tmpEventData.updatedAt._seconds * 1000 + tmpEventData.updatedAt._nanoseconds / 1000000)
    else if (tmpEventData.updatedAt)
      tmpEventData.updatedAt = new Date(tmpEventData.updatedAt);
    // console.log(tmpEventData.additionalFields);
    setEventData({ ...tmpEventData });
    // console.log(tmpEventData.createdAt);
  }
  const getBaseUrl = () => {
    let url = window.location.protocol + "//" + window.location.hostname;
    if (window.location.port) {
      url += ":" + window.location.port;
    }
    return url + "/";
  }
  useEffect(() => {
    // if (!eventData || !eventData?.id) {
    //   toast.warning("Select to edit or view registrations for an event before continuing..");
    //   return <Redirect to="/manage" />;
    // }
    // let tmpShowModules = localStorage.getItem('showModules');
    // if (tmpShowModules) {
    //   setShowModules(JSON.parse(tmpShowModules));
    // } else {
    //   setShowModules({details:true,design:false,notifications:true,configuration:true,passcodes:false,capacities:false,leads:false,fields:false});
    // }
    loadInit();
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });
    db.collection("settings")
      .doc("current")
      .get()
      .then((docRef) => {
        setSettings(docRef.data());
      });
    db.collection("dealers")
      .get()
      .then((qs) => {
        if (qs.docs.length > 0) {
          let tmpItems = [];
          qs.docs.map((item, index) => {
            let tmpItem = item.data();
            tmpItem.id = item.id;
            tmpItems.push(tmpItem);
          });
          setDealers(tmpItems);
          let options = [{ value: "", label: "No preference" }];
          let dealerOptions = [];
          tmpItems?.forEach((dealer, index) => {
            dealerOptions.push({ value: dealer.code, label: dealer.name + " (" + dealer.code + ")" });
          });
          dealerOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
          setDealerOptions(options.concat(dealerOptions));
        }
      });
    db.collection("campaigncodes")
      .get()
      .then((qs) => {
        console.log(qs.docs.length);
        if (qs.docs.length > 0) {
          let tmpItems = [];
          let dt = moment().add(-1,'years').format('YYYY-MM-DD');
          qs.docs.map((item, index) => {
            let tmpItem = item.data();
            tmpItem.id = item.id;
            if (tmpItem.approved && tmpItem.eventDate && tmpItem.eventDate >= dt)
              tmpItems.push({ label: tmpItem.program + " - " + tmpItem.eventName, value: tmpItem });
            else
            console.log(tmpItem);
          });
          setCampaignCodes(tmpItems);
        }
      });
    db.collection("models")
      .get()
      .then((qs) => {
        if (qs.docs.length > 0) {
          let tmpItems = [];
          qs.docs.map((item, index) => {
            let tmpItem = item.data();
            tmpItem.id = item.id;
            tmpItems.push(tmpItem);
          });
          setModels(tmpItems);
        }
      });
    if (user.role <= 1) {
      let queryRef = null;
      setTimeout(() => {
        var getUserList = firebase.functions().httpsCallable("app/userList");
        getUserList().then(async function (result) {
          setUserList(result.data);
        });
      }, 20);
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  const handleScroll = (e) => {
    let target = document.body;
    // console.log(target);
    let currentScrollY = window.scrollY;
    if (currentScrollY > 50 && currentScrollY < target.clientHeight - window.innerHeight + 100) {
      setFloatingSave(true);
    }
    if (currentScrollY >= target.clientHeight - window.innerHeight) {
      setFloatingSave(false);
    }
  }
  const arraySearch = (array, keyword, i) => {
    const searchTerm = keyword.toLowerCase();
    console.log(array);
    if (array.length === 0)
      return false;
    let arrayIndex = array.findIndex(value => value && value.fieldType?.toLowerCase() === searchTerm);
    if (arrayIndex < 0 || arrayIndex === i)
      return true;
    else
      return false;
  }
  const handlePasscodeFileRead = async (event) => {
    let valid = 0;
    let invalid = 0;
    const file = event.target.files[0];
    if (file.name.indexOf(".csv") === -1) {
      alert(
        "File must be a valid csv file, download the provided template if needed."
      );
      return;
    }
    const csv = await processsFile(file);
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    var j = 0;
    while (j < allTextLines.length) {
      var batch = db.batch();
      var k = j + 500;
      if (k > allTextLines.length) {
        k = allTextLines.length;
      }
      for (var i = j; i < k; i++) {
        var data = allTextLines[i].split(",");
        if (data.length == 1) {
          valid = valid + 1;
          var docRef = db.collection("passcodes").doc(); //automatically generate unique id
          batch.set(docRef, { eventId: eventData?.id, code: data[0] });
        } else if (data.length == 1 && data[0] == "") {
          console.log("empty line");
        } else {
          invalid = invalid + 1;
        }
      }
      j = k;
      batch.commit();
    }
    toast.success(
      "Import complete: " + valid ? valid : 0 + " of " + (valid + invalid) + " records."
    );
    setEventData({
      ...eventData,
      passcodeCount: valid
    });
    setImportPasscode(false);
  };
  const processsFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      fileReader.readAsText(file);
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.role > 2) {
      alert('Only admins and managers can save / update an event.');
      return;
    }
    //check public
    //check group
    // if (!eventData?.groupId) {
    //   toast.error('You must select a group');
    //   return;
    // }
    let tmpEventData = { ...eventData };
    if (tmpEventData.publicUrl)
      tmpEventData.publicUrl = tmpEventData.publicUrl.toLowerCase();
    if (tmpEventData?.id && tmpEventData?.id != 1) {
      console.log(tmpEventData.updatedAt);
      if (tmpEventData.updatedAt) {
        var checkEventData = await db.collection("events").doc(tmpEventData?.id).get();
        let tmpCheckUpdatedAt = checkEventData.data()?.updatedAt;
        if (tmpEventData.updatedAt && tmpCheckUpdatedAt?.seconds && tmpCheckUpdatedAt?.nanoseconds) {
          let updatedAt = new Date(tmpEventData?.updatedAt);
          let checkUpdatedAt = new Date(tmpCheckUpdatedAt.seconds * 1000 + tmpCheckUpdatedAt.nanoseconds / 1000000);
          console.log(checkUpdatedAt);
          console.log(tmpEventData?.updatedAt);
          if (checkUpdatedAt.getTime() > updatedAt.getTime()) {
            let continueSave = window.confirm("This event has been saved by someone else since it was loaded.  Do you want to overwrite any changes made with this data?  If not, press cancel, and reload your event from the dashboard.");
            if (continueSave)
              console.log('User overwrite previous save.');
            else
              return;
          }
        }
      }
      if (!tmpEventData.createdAt || isNaN(tmpEventData.createdAt)) {
        tmpEventData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      }
      tmpEventData.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      console.log(tmpEventData);
      db.collection("events").doc(tmpEventData?.id).set(tmpEventData)
        .then(() => {
          db.collection("events").doc(tmpEventData?.id).get()
            .then(doc => {
              if (!doc.exists) {
                console.log("No such document!"); //Error
              } else {
                let evtData = doc.data();
                if (evtData.createdAt)
                  evtData.createdAt = new Date(evtData.createdAt.seconds * 1000 + evtData.createdAt.nanoseconds / 1000000)
                if (evtData.updatedAt)
                  evtData.updatedAt = new Date(evtData.updatedAt.seconds * 1000 + evtData.updatedAt.nanoseconds / 1000000)
                setEventData(evtData);
                setCurrentEventData(evtData);
                sessionStorage.setItem('eventData', JSON.stringify(evtData));
                let exp = moment(evtData.endDate).clone().add(91, 'days');
                if (endDateChange) {
                  db.collection("registrations")
                    .where("eventId", "==", evtData.id)
                    .get()
                    .then(function async(querySnapshot) {
                      let len = querySnapshot.docs.length;
                      if (len > 0) {
                        toast.warning("Updating registration expirations please wait to see event saved successfully message.", 10000);
                        for (let i = 0; i < len; i++) {
                          querySnapshot.docs[i].ref.update({
                            expiration: exp.toDate(),
                            email: querySnapshot.docs[i].data().email ? querySnapshot.docs[i].data().email.toLowerCase() : ""
                          }).then(function () {
                            // console.log("Document " + (i + 1) + " of " + len + " successfully updated!");
                            if (i == len - 1) {
                              setEventData(evtData);
                              setCurrentEventData(evtData);
                              sessionStorage.setItem('eventData', JSON.stringify(evtData));
                              toast.success("Event saved successfully.");
                            }
                          })
                            .catch(function (error) {
                              // The document probably doesn't exist.
                              console.error("Error updating document: ", error);
                            });
                        }
                      } else {
                        setEventData(tmpEventData);
                        setCurrentEventData(tmpEventData);
                        sessionStorage.setItem('eventData', JSON.stringify(tmpEventData));
                        toast.success("Event saved successfully.");
                      }
                      // });
                      // console.log("All documents successfully updated.");
                    })
                    .catch(function (error) {
                      console.log("Error getting documents: ", error);
                      setEventData(tmpEventData);
                      setCurrentEventData(tmpEventData);
                      sessionStorage.setItem('eventData', JSON.stringify(tmpEventData));
                      toast.success("Event saved successfully.");
                    });
                } else {
                  setEventData(evtData);
                  setCurrentEventData(evtData);
                  sessionStorage.setItem('eventData', JSON.stringify(evtData));
                  console.log()
                  toast.success("Event saved successfully.");
                }
              }
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            })
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    } else {
      tmpEventData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      tmpEventData.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("events")
        .add(eventData)
        .then((docRef) => {
          setEventData({ ...tmpEventData, id: docRef.id });
          let tmpEvent = tmpEventData;
          tmpEvent.id = docRef.id;
          setEventData(tmpEvent);
          setCurrentEventData(tmpEvent);
          sessionStorage.setItem('eventData', JSON.stringify(tmpEvent));
          toast.success("Event saved successfully.");
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  };
  const csvJSON = (text, quoteChar = '"', delimiter = ',') => {
    var rows = text.split("\n");
    var headers = rows[0].split(",");
    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');
    const match = line => [...line.matchAll(regex)]
      .map(m => m[2])
      .slice(0, -1);
    var lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);
    return lines.map(line => {
      return match(line).reduce((acc, cur, i) => {
        // replace blank matches with `null`
        let val = cur.length <= 0 ? null : Number(cur) || cur;
        const key = heads[i] ?? `{i}`;
        if (key === 'id' || key === 'itemIndex' || key === 'reserved') {
          val = +val;
        }
        return { ...acc, [key]: val };
      }, {});
    });
  }
  const handleCSVChange = async (e) => {
    console.log(e.target.value);
    let jsonData = csvJSON(e.target.value);
    // console.log(jsonData);
    setEventData({
      ...eventData,
      sessions: jsonData,
    });
  }
  const handleChange = (e) => {
    if (e.target.name == "endDate") {
      setEndDateChange(true);
    }
    if (e.target.name == "groupId") {
      setEventData({
        ...eventData,
        [e.target.name]: e.target.value,
        groupName: e.target.options[e.target.selectedIndex].text
      });
    } else {
      setEventData({
        ...eventData,
        [e.target.name]: e.target.value,
      });
      console.log(eventData);
    }
  };
  const downloadPNG = (e) => {
    console.log(e);
    let c = document.getElementById("qr-code").firstChild;
    var a = document.createElement("a");
    a.href = c.toDataURL("image/png");
    a.download = "qr-code.png";
    a.click();
  };
  const downloadRegPNG = (e) => {
    console.log(e);
    let c = document.getElementById("qr-code-reg").firstChild;
    var a = document.createElement("a");
    a.href = c.toDataURL("image/png");
    a.download = "qr-code-reg.png";
    a.click();
  };
  const downloadGalleryPNG = (e) => {
    console.log(e);
    let c = document.getElementById("qr-code-gallery").firstChild;
    var a = document.createElement("a");
    a.href = c.toDataURL("image/png");
    a.download = "qr-code-gallery.png";
    a.click();
  };
  const fieldList = ['id', 'eventId', 'company', 'title', 'firstName', 'lastName', 'email', 'phone', 'address1', 'address2', 'city', 'state', 'postal', 'country', 'experience', 'purchaseTimeFrame', 'dealer', 'modelInterest', 'adyenResultCode', 'adyenRefusalReason', 'adyenRef', 'adyenRefDate', 'adyenLink', 'adyenLinkId', 'adyenLinkExpire', 'status', 'adyenPayemntStatus', 'hasWaiver', 'createdAt', 'updatedAt', 'leadSent'];
  const uploadCloudinary = (file, pubId, folder, key, transformation, list, i, field) => {
    //uploadCloudinary(base64, eventData?.id + "_" + x + "_" + i, "/pcna/ezentrum/" + eventData?.id + "/", x + i, null, true, i);
    let uploadData = { file: file, public_id: pubId, folder: folder, transformation: transformation };
    var upload = firebase.functions().httpsCallable("app/uploadCloudinary");
    upload(uploadData)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        } else {
          toast.success("Image upload successful.");
          let tmpInputs = eventData;
          let u = result.data.url;
          console.log(u);
          console.log(list);
          console.log(key);
          console.log('======')
          //.split("/");
          // u.splice(6,1);
          // u = u.join("/");
          if (!list)
            tmpInputs[key] = u;
          let img = document.getElementById(key);
          console.log(img);
          console.log(key);
          img.src = img.src + "?v=" + Date.now(); // trick browser into reload
          if (!list) {
            setEventData({ ...tmpInputs });
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);
            setEventData(tmpInputs);
            setCurrentEventData(tmpInputs);
            sessionStorage.setItem('eventData', JSON.stringify(tmpInputs));
          } else {
            let tmpImages = eventData[list];
            if (!field) {
              tmpImages[i] = u;
              console.log(tmpImages);
              setEventData({ ...eventData, tmpImages: tmpImages });
            } else {
              console.log(tmpImages);
              tmpImages[i][field] = u;
            }
            setEventData({ ...eventData, tmpImages: tmpImages });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  }
  const handleChangeItem = useCallback((idkey, value) => {
    // console.log(idkey,value);
    // let tmpInputs = {...eventData};
    // tmpInputs[idkey] = value;
    // setEventData(tmpInputs);
    // // console.log(tmpInputs);
    // setEventData({...eventData,[idkey]:value});
    setEventData(value);
  }, []);
  const removeCloudinary = (pubId, key, disableNotifications) => {
    var remove = firebase.functions().httpsCallable("app/removeCloudinary");
    remove(pubId)
      .then((result) => {
        if (result?.data.message) {
          toast.warning(result?.data.message);
          return "";
        } else {
          if (!disableNotifications) toast.success("Image removal successful.");
          let tmpInputs = eventData;
          if (key) {
            tmpInputs[key] = "";
            let img = document.getElementById(key);
            // remove element?
            setEventData(tmpInputs);
            db.collection("events").doc(tmpInputs.id).set(tmpInputs);
            setEventData(tmpInputs);
            setCurrentEventData(tmpInputs);
            sessionStorage.setItem('eventData', JSON.stringify(tmpInputs));
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!disableNotifications) toast.error(error.message);
      });
  }
  const removeBannerImage = () => {
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_banner", "bannerImage");
    setEventData({ ...eventData, bannerImage: "" });
  }
  const removeEmailBannerImage = () => {
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_banner_email", "bannerEmailImage");
    setEventData({ ...eventData, bannerEmailImage: "" });
  }
  const removeLogoImage = () => {
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_logo", "logoImage");
    setEventData({ ...eventData, logoImage: "" });
  }
  const removefeaturedImage = () => {
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_featured_banner", "featuredImage");
    setEventData({ ...eventData, featuredImage: "" })
  }
  const removeMobileBannerImage = () => {
    setEventData({ ...eventData, mobileBannerImage: "" });
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_mobile_banner", "mobileBannerImage");
  }
  const removeMobileEmailBannerImage = () => {
    setEventData({ ...eventData, mobileEmailBannerImage: "" });
    removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_mobile_email_banner", "mobileEmailBannerImage");
  }
  const handleFileRead = async (event) => {
    console.log('3');
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_banner", "/pcna/ezentrum/" + eventData?.id + "/", "bannerImage", { crop: "fill", height: 580, width: 1600 });
  };
  const handleEmailFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_email_banner", "/pcna/ezentrum/" + eventData?.id + "/", "emailBannerImage", { crop: "fill", width: 800 });
  };
  const handleLogoFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_logo", "/pcna/ezentrum/" + eventData?.id + "/", "logoImage");
  };
  const handleMobileFileRead = async (event) => {
    console.log('4');
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_mobile_banner", "/pcna/ezentrum/" + eventData?.id + "/", "mobileBannerImage", { crop: "fill", width: 800 });
  };
  const handleMobileEmailFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_mobile_email_banner", "/pcna/ezentrum/" + eventData?.id + "/", "mobileEmailBannerImage", { crop: "fill", height: 600, width: 800 });
  };
  const handleGalleryImageRead = async (event, i) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    uploadCloudinary(base64, eventData?.id + "_galleryImage_" + i, "/pcna/ezentrum/" + eventData?.id + "/", "galleryImage" + i, null, 'galleryImages', i);
  };
  const handleFeaturedFileRead = async (event) => {
    console.log('5');
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    var url = uploadCloudinary(base64, eventData?.id + "_featured_banner", "/pcna/ezentrum/" + eventData?.id + "/", "featuredImage", { crop: "fill", height: 600, width: 800 });
    setEventData({ ...eventData, featuredImage: base64 });
  };
  const handleListChange = async (event, i, x, checkbox, v) => {
    let tmpList = eventData[x];
    if (event.target.name === 'fieldName');
    if (fieldList.indexOf(event.target.value) >= 0) {
      toast.error('Invalid field name, field already in use.');
      return;
    }
    let val = v ? v : (checkbox ? event.target.checked : event.target.value ? event.target.value : "");
    tmpList[i] = { ...tmpList[i], [event.target.name]: val };
    setEventData({ ...eventData, tmpList: tmpList });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    if (!user.isAuthenticated) {
      history("/manage/sign-in");
    }
  }, [user]);
  useEffect(() => {
    try {
      setSessionData(eventData?.sessions ? eventData?.sessions : []);
    } catch (e) {
      console.log('error', e);
    }
  }, [eventData?.sessions]);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    let tmpItems = eventData?.ctaList;
    let dragCard = eventData?.ctaList[dragIndex];
    tmpItems.splice(dragIndex, 1);
    console.log(dragCard);
    //   Array.prototype.Insert = function (item, before) {
    //     if (!item) return;
    //     if (before == null || before < 0 || before > this.length - 1) {
    //         this.push(item);
    //         return;
    //     }
    //     this.splice(before, 0,item );
    // }
    // tmpItems.Insert(dragCard,hoverIndex);
    tmpItems.splice(hoverIndex, 0, dragCard);
    console.log(tmpItems);
    setEventData({ ...eventData, ctaList: tmpItems });
    // setData(data.items, {
    //     $splice: [
    //         [dragIndex, 1],
    //         [hoverIndex, 0, dragCard],
    //     ],
    // }));
  }, [eventData?.ctaList]);
  // useEffect(() => {
  //   eventData?.multiUsePasscodes?.map((x, i) => {
  //     let tmpPasscodeUserRefs = passcodeUserRefs;
  //     tmpPasscodeUserRefs[i] = React.createRef();
  //     setPasscodeUserRefs(tmpPasscodeUserRefs);
  //   });
  // }, [eventData]);
  const updateShowModules = (module, value) => {
    let tmpShowModules = { ...showModules };
    tmpShowModules[module] = value;
    setShowModules(tmpShowModules);
    localStorage.setItem('showModules', JSON.stringify(tmpShowModules));
  }
  return (
    <div className="container">
      <h1>{eventData?.sysname ? eventData?.sysname : eventData?.name}</h1>
      <div>Id: {eventData?.id}</div>
      <div className="py-2"></div>
      <div className="card mb-4">
        <div className="card-header" onClick={() => { updateShowModules('details', !showModules.details) }}>
          <h2>Event Details</h2>
          <div>
            <span className={showModules.details ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.details ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.details &&
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, sysname: !help.sysname });
                    }}
                  >
                    Event System Name{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.sysname ? "fs-xs" : "d-none"}>
                    System Name for the event.
                  </div>
                  <input
                    type="text"
                    id="sysname"
                    name="sysname"
                    value={eventData?.sysname || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, eventName: !help.eventName });
                    }}
                  >
                    Event Name{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.eventName ? "fs-xs" : "d-none"}>
                    Name for the event.
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={eventData?.name || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, subtitle: !help.subtitle });
                    }}
                  >
                    Event Subtitle{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.subtitle ? "fs-xs" : "d-none"}>
                    Subtitle for the event.
                  </div>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    value={eventData?.subtitle || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label className="form-label">Start Date</label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={eventData?.startDate || ''}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group">
                      <label className="form-label">End Date</label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={eventData?.endDate || ''}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Allowed Users {eventData?.allowedUsers && eventData?.allowedUsers != "" ? "(comma separated addresses)" : ""}</label>
                  {user.role <= 1 ? <>
                    {(!eventData?.allowedUsers || eventData?.allowedUsers == "") ?
                      <Multiselect
                        options={userList}
                        style={multiSelectStyle}
                        displayValue="name"
                        ref={userListRef}
                        selectedValues={eventData?.usersAllowed}
                        closeIcon="close"
                        onSelect={() => {
                          console.log(userListRef.current.getSelectedItems());
                          setEventData({
                            ...eventData,
                            usersAllowed: userListRef.current.getSelectedItems(),
                          });
                        }}
                        onRemove={() => {
                          console.log(userListRef.current.getSelectedItems());
                          setEventData({
                            ...eventData,
                            usersAllowed: userListRef.current.getSelectedItems(),
                          });
                        }}
                      /> :
                      <input
                        type="text"
                        disabled={user.role > 1}
                        id="allowedUsers"
                        name="allowedUsers"
                        value={eventData?.allowedUsers || ''}
                        onChange={handleChange}
                        className="form-control"
                      />
                    }</> : <div>{eventData?.usersAllowed?.map((x, i) => {
                      return <span key={i}>{x.name}{i < eventData?.usersAllowed.length - 1 ? "," : ""}</span>
                    })}</div>
                  }
                </div>
                <div className="form-group">
                  <label className="form-label">Status</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={handleChange}
                    value={eventData?.status}
                  >
                    <option value="Active">Active</option>
                    <option value="Archived">Archived</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="card mb-4">
        <div className="card-header" onClick={() => { updateShowModules('design', !showModules.design) }}>
          <h2>Event Design</h2>
          <div>
            <span className={showModules.design ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.design ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.design &&
          <div className="card-body">
            <div className={"form-group"}>
              <label className="checkbox-container">
                Enable public landing page?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowPublic: e.target.checked,
                    });
                  }}
                  id="allowPublic"
                  name="allowPublic"
                  value="true"
                  checked={eventData?.allowPublic || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Enable Registration?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowReg: e.target.checked,
                    });
                  }}
                  id="allowReg"
                  name="allowReg"
                  value="true"
                  checked={eventData?.allowReg || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show FAQ Page for event.
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowFAQ: e.target.checked,
                    });
                  }}
                  id="allowFAQ"
                  name="allowFAQ"
                  checked={eventData?.allowFAQ || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={eventData?.allowFAQ ? "form-group" : "d-none"}>
              <label className="checkbox-container">
                Show Health and Safety Banner for event.
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowCovidBanner: e.target.checked,
                    });
                  }}
                  id="allowCovidBanner"
                  name="allowCovidBanner"
                  checked={eventData?.allowCovidBanner}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show Event Gallery?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowGallery: e.target.checked,
                    });
                  }}
                  id="allowGallery"
                  name="allowGallery"
                  checked={eventData?.allowGallery || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.allowGallery &&
              <div className="form-group">
                <label>Gallery Passcode
                </label>
                <input
                  type="text"
                  id="galleryPasscode"
                  name="galleryPasscode"
                  value={eventData?.galleryPasscode || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            }
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show Terms and Conditions Page for event.
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowTerms: e.target.checked,
                    });
                  }}
                  id="allowTerms"
                  name="allowTerms"
                  checked={eventData?.allowTerms || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={(eventData?.allowPublic || eventData?.allowReg) ? "row" : "d-none"}>
              <div className={eventData?.publicUrl ? "col-auto" : "d-none"}>
                {eventData?.allowPublic &&
                  <div>
                    <div className="py-3 bold-font">Event Landing Page</div>
                    <div id="qr-code">
                      <QRCode
                        size={1024}
                        value={getBaseUrl() + eventData?.publicUrl}
                      />
                    </div>
                    <button
                      className="btn btn-link py-0"
                      onClick={downloadPNG}
                    >
                      Download PNG
                    </button>
                  </div>
                }
                {eventData?.allowReg &&
                  <div className="">
                    <div className="py-3 bold-font">Event Registration Page</div>
                    <div id="qr-code-reg">
                      <QRCode
                        size={1024}
                        value={getBaseUrl() + "register/" + eventData?.publicUrl}
                      />
                    </div>
                    <button
                      className="btn btn-link py-0"
                      onClick={downloadRegPNG}
                    >
                      Download PNG
                    </button>
                  </div>
                }
                {eventData?.allowGallery &&
                  <div className="">
                    <div className="py-3 bold-font">Event Gallery Page</div>
                    <div id="qr-code-gallery">
                      <QRCode
                        size={1024}
                        value={getBaseUrl() + eventData?.publicUrl + "/gallery"}
                      />
                    </div>
                    <button
                      className="btn btn-link py-0"
                      onClick={downloadGalleryPNG}
                    >
                      Download PNG
                    </button>
                  </div>
                }
              </div>
              <div className="col">
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, publicUrl: !help.publicUrl });
                    }}
                  >
                    Public Url{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.publicUrl ? "fs-xs" : "d-none"}>
                    Url to access the event for self registration.
                    /public-url
                  </div>
                  <input
                    type="text"
                    id="publicUrl"
                    name="publicUrl"
                    value={eventData?.publicUrl || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                {eventData?.allowPublic &&
                  <div className="pb-3"><a href={getBaseUrl() + eventData?.publicUrl} target="_blank">{getBaseUrl() + eventData?.publicUrl}</a></div>
                }
                {eventData?.allowReg &&
                  <div className="pb-3"><a href={getBaseUrl() + "register/" + eventData?.publicUrl} target="_blank">{getBaseUrl() + "register/" + eventData?.publicUrl}</a></div>
                }
                {eventData?.allowGallery &&
                  <div className="pb-3"><a href={getBaseUrl() + eventData?.publicUrl + "/gallery"} target="_blank">{getBaseUrl() + eventData?.publicUrl + "/gallery"}</a></div>
                }
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({
                        ...help,
                        seoPrimaryKeyword: !help.seoPrimaryKeyword,
                      });
                    }}
                  >
                    SEO Primary Keyword{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div
                    className={help.seoPrimaryKeyword ? "fs-xs" : "d-none"}
                  >
                    Primary Keyword should also be in the Event Name.
                  </div>
                  <input
                    type="text"
                    id="seoPrimaryKeyword"
                    name="seoPrimaryKeyword"
                    value={eventData?.seoPrimaryKeyword || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({
                        ...help,
                        seoKeywords: !help.seoKeywords,
                      });
                    }}
                  >
                    SEO Keywords{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div
                    className={help.seoKeywords ? "fs-xs" : "d-none"}
                  >
                    Keywords for the page.
                  </div>
                  <input
                    type="text"
                    id="seoKeywords"
                    name="seoKeywords"
                    value={eventData?.seoKeywords || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({
                        ...help,
                        seoDescription: !help.seoDescription,
                      });
                    }}
                  >
                    SEO Description {eventData?.seoDescription ? eventData?.seoDescription.length : 0}/150{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div
                    className={help.seoDescription ? "fs-xs" : "d-none"}
                  >
                    SEO Friendly description of the site.
                  </div>
                  <input
                    type="text"
                    maxLength="150"
                    id="seoDescription"
                    name="seoDescription"
                    value={eventData?.seoDescription || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            {/* <div className={eventData?.listedEvent ? "" : "d-none"}>
              <h3>Experience Tile Details</h3>
              <div className="listed-container">
                <div className="py-2"></div>
                <div className="form-group">
                  <label className="form-label">Featured Image (800x600)</label>
                  <div>
                    <input
                      id="originalFeaturedFileName"
                      type="file"
                      name="originalFeaturedFileName"
                      onChange={(e) => handleFeaturedFileRead(e)}
                    />
                  </div>
                </div>
                <div className={eventData?.featuredImage ? "banner-image mobile-banner" : "d-none"}>
                  <img id="featuredImage"
                    className=""
                    src={eventData?.featuredImage}
                    alt={eventData?.featuredName}
                  />
                  <div>
                    <button className={"btn btn-link"} onClick={removefeaturedImage}>Remove Image</button>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, featuredName: !help.featuredName });
                    }}
                  >
                    Featured Image Name{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.featuredName ? "fs-xs" : "d-none"}>
                    Name for the Featured Image.
                  </div>
                  <input
                    type="text"
                    id="featuredName"
                    name="featuredName"
                    value={eventData?.featuredName || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, featuredSubtitle: !help.featuredSubtitle });
                    }}
                  >
                    Featured Image Subtitle{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.featuredSubtitle ? "fs-xs" : "d-none"}>
                    Subtitle for the Featured Image.
                  </div>
                  <input
                    type="text"
                    id="featuredSubtitle"
                    name="featuredSubtitle"
                    value={eventData?.featuredSubtitle || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="py-2"></div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, ctaLabel1: !help.ctaLabel1 });
                    }}
                  >
                    Call To Action #1 Label{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.ctaLabel1 ? "fs-xs" : "d-none"}>
                    Label for Call to Action #1.
                  </div>
                  <input
                    type="text"
                    id="ctaLabel1"
                    name="ctaLabel1"
                    value={eventData?.ctaLabel1 || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, ctaLink1: !help.ctaLink1 });
                    }}
                  >
                    Call To Action #1 Link{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.ctaLink1 ? "fs-xs" : "d-none"}>
                    Link for Call to Action #1, leave blank to link to event landing page.
                  </div>
                  <input
                    type="text"
                    id="ctaLink1"
                    name="ctaLink1"
                    value={eventData?.ctaLink1 || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #1 New Window?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaExternal1: e.target.checked,
                        });
                      }}
                      id="ctaExternal1"
                      name="ctaExternal1"
                      checked={eventData?.ctaExternal1 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #1 Primary Button?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaPrimary1: e.target.checked,
                        });
                      }}
                      id="ctaPrimary1"
                      name="ctaPrimary1"
                      checked={eventData?.ctaPrimary1 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #1 Include &gt; Icon?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaIcon1: e.target.checked,
                        });
                      }}
                      id="ctaIcon1"
                      name="ctaIcon1"
                      checked={eventData?.ctaIcon1 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, ctaLabel2: !help.ctaLabel2 });
                    }}
                  >
                    Call To Action #2 Label{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.ctaLabel2 ? "fs-xs" : "d-none"}>
                    Label for Call to Action #2.
                  </div>
                  <input
                    type="text"
                    id="ctaLabel2"
                    name="ctaLabel2"
                    value={eventData?.ctaLabel2 || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, ctaLink2: !help.ctaLink2 });
                    }}
                  >
                    Call To Action #2 Link{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.ctaLink2 ? "fs-xs" : "d-none"}>
                    Link for Call to Action #2, leave blank to link to event landing page.
                  </div>
                  <input
                    type="text"
                    id="ctaLink2"
                    name="ctaLink2"
                    value={eventData?.ctaLink2 || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #2 New Window?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaExternal2: e.target.checked,
                        });
                      }}
                      id="ctaExternal2"
                      name="ctaExternal2"
                      checked={eventData?.ctaExternal2 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #2 Primary Button?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaPrimary2: e.target.checked,
                        });
                      }}
                      id="ctaPrimary2"
                      name="ctaPrimary2"
                      checked={eventData?.ctaPrimary2 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Call To Action #2 Include &gt; Icon?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          ctaIcon2: e.target.checked,
                        });
                      }}
                      id="ctaIcon2"
                      name="ctaIcon2"
                      checked={eventData?.ctaIcon2 || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div> */}
            <div className="py-3"></div>
            <h3>Event Landing Page</h3>
            <div className="form-group">
              <label className="form-label">Banner Image (1600x580)</label>
              <div>
                <input
                  id="originalFileName"
                  type="file"
                  name="originalFileName"
                  onChange={(e) => handleFileRead(e)}
                />
              </div>
            </div>
            <div className="form-group pt-3">
              <label className="checkbox-container">
                Hide banner image overlay?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      hideOverlay: e.target.checked,
                    });
                  }}
                  id="hideOverlay"
                  name="hideOverlay"
                  checked={eventData?.hideOverlay || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-group pt-3">
              <label className="checkbox-container">
                Hide banner Chevron (&gt;) in title?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      hideBannerChevron: e.target.checked,
                    });
                  }}
                  id="hideBannerChevron"
                  name="hideBannerChevron"
                  checked={eventData?.hideBannerChevron || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={eventData?.bannerImage ? "banner-image" : "d-none"}>
              <img id="bannerImage"
                className="w-100"
                src={eventData?.bannerImage}
                alt={eventData?.name}
              />
              <div>
                <button className={"btn btn-link"} onClick={removeBannerImage}>Remove Image</button>
              </div>
            </div>
            <div className="py-2"></div>
            <div className="form-group">
              <label className="form-label">Mobile Banner Image (800x600)</label>
              <div>
                <input
                  id="originalMobileFileName"
                  type="file"
                  name="originalMobileFileName"
                  onChange={(e) => handleMobileFileRead(e)}
                />
              </div>
            </div>
            <div className={eventData?.mobileBannerImage ? "banner-image mobile-banner" : "d-none"}>
              <img
                className=""
                id="mobileBannerImage"
                src={eventData?.mobileBannerImage}
                alt={eventData?.name}
              />
              <div>
                <button className={"btn btn-link"} onClick={removeMobileBannerImage}>Remove Image</button>
              </div>
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, bannerName: !help.bannerName });
                }}
              >
                Banner Image Name{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.bannerName ? "fs-xs" : "d-none"}>
                Name for the Banner Image.
              </div>
              <input
                type="text"
                id="bannerName"
                name="bannerName"
                value={eventData?.bannerName || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, bannerSubtitle: !help.bannerSubtitle });
                }}
              >
                Banner Image Subtitle{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.bannerSubtitle ? "fs-xs" : "d-none"}>
                Subtitle for the Banner Image.
              </div>
              <input
                type="text"
                id="bannerSubtitle"
                name="bannerSubtitle"
                value={eventData?.bannerSubtitle || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Logo Image (512x512)</label>
              <div>
                <input
                  id="originalFileName"
                  type="file"
                  name="originalFileName"
                  onChange={(e) => handleLogoFileRead(e)}
                />
              </div>
            </div>
            <div className={eventData?.logoImage ? "banner-image  mobile-banner" : "d-none"}>
              <img id="logoImage"
                className=""
                src={eventData?.logoImage}
                alt={eventData?.name}
              />
              <div>
                <button className={"btn btn-link"} onClick={removeLogoImage}>Remove Image</button>
              </div>
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, dates: !help.dates });
                }}
              >
                Event Dates{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.dates ? "fs-xs" : "d-none"}>
                Description of when the event will happen.
              </div>
              <input
                type="text"
                id="dates"
                name="dates"
                value={eventData?.dates || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, dates2: !help.dates2 });
                }}
              >
                Event Dates #2{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.dates2 ? "fs-xs" : "d-none"}>
                Description #2 of when the event will happen.
              </div>
              <input
                type="text"
                id="dates2"
                name="dates2"
                value={eventData?.dates2 || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, access: !help.access });
                }}
              >
                Event Access{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.access ? "fs-xs" : "d-none"}>
                Description of access level to event.
              </div>
              <input
                type="text"
                id="access"
                name="access"
                value={eventData?.access || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, bgColor: !help.bgColor });
                }}
              >
                Background Color{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.access ? "fs-xs" : "d-none"}>
                Background color to replce white on the page.
              </div>
              <input
                type="text"
                id="bgColor"
                name="bgColor"
                value={eventData?.bgColor || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Hide Header
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      hideHeader: e.target.checked,
                    });
                  }}
                  id="hideHeader"
                  name="hideHeader"
                  checked={eventData?.hideHeader || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Hide Footer
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      hideFooter: e.target.checked,
                    });
                  }}
                  id="hideFooter"
                  name="hideFooter"
                  checked={eventData?.hideFooter || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="py-3"><hr /></div>
            <div className="form-group">
              <h2>Overview Copy</h2>
              <div className="copy-container">
                <div className={eventData?.overviewCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.overviewCopy
                    ),
                  }}
                ></div>
                <button className="btn btn-default" onClick={() => { setEditCopy("overviewCopy"); }}>Edit Overview Copy</button>
                {!eventData?.disableRegistration &&
                  <div className="form-group pt-3">
                    <label className="checkbox-container">
                      Show overview copy on register page
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            showOverviewReg: e.target.checked,
                          });
                        }}
                        id="showOverviewReg"
                        name="showOverviewReg"
                        checked={eventData?.showOverviewReg || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                }
              </div>
            </div>
            <div className="form-group">
              <h2>Register Overview Copy</h2>
              <div className="copy-container">
                <div className={eventData?.registerOverviewCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.registerOverviewCopy
                    ),
                  }}
                ></div>
                <button className="btn btn-default" onClick={() => { setEditCopy("registerOverviewCopy"); }}>Edit Register Overview Copy</button>
              </div>
            </div>
            {/* <div className="py-3"><hr /></div>
              <h2>Locations</h2>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Show Locations?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        showLocations: e.target.checked,
                      });
                    }}
                    id="showLocations"
                    name="showLocations"
                    checked={eventData?.showLocations || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.showLocations &&
                <>
                  <div className="alert alert-danger">Coming Soon</div>
                  <div className="text-end">
                    <button
                      className={"btn btn-theme"}
                      onClick={() => {
                        setEventData({
                          ...eventData,
                          locationList: [...eventData?.locationList, {}],
                        });
                      }}
                    >
                      + LOCATION
                    </button>
                  </div>
                  <div className="py-3"></div>
                  {eventData?.locationList?.map((cta, index) => (
                    <div key={index} className={index % 2 == 0 ? "alt-row cta-list-item" : "cta-list-item"}>
                      <div className={"form-group"}>
                        <label
                          className="form-label form-help"
                          onClick={() => {
                            setHelp({ ...help, locationTitle: !help.locationTitle });
                          }}
                        >
                          Location #{index + 1} Title{" "}
                          <i className="xs">
                            <InfoIcon />
                          </i>
                        </label>
                        <div className={help.name ? "fs-xs" : "d-none"}>
                          Title for Location #{index + 1}.
                        </div>
                        <input
                          type="text"
                          id={"locationTitle" + index}
                          name="locationTitle"
                          value={eventData?.locationList[index].locationTitle || ''}
                          onChange={(e) => { handleListChange(e, index, 'locationList') }}
                          className="form-control"
                        />
                      </div>
                      <div className={"form-group"}>
                        <label
                          className="form-label form-help"
                          onClick={() => {
                            setHelp({ ...help, locationDescription: !help.locationDescription });
                          }}
                        >
                          Location Description #{index + 1} {" "}
                          <i className="xs">
                            <InfoIcon />
                          </i>
                        </label>
                        <div className={help.name ? "fs-xs" : "d-none"}>
                          Description for Location #{index + 1}.
                        </div>
                        <input
                          type="text"
                          id={"locationDescription" + index}
                          name="locationDescription"
                          value={eventData?.locationList[index].locationDescription || ''}
                          onChange={(e) => { handleListChange(e, index, 'locationList') }}
                          className="form-control"
                        />
                      </div>
                      <div className="text-end">
                        <i className="del-icon ml-2" onClick={(e) => {
                          removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_locationList_" + index, null);
                          let locationList = eventData?.locationList;
                          locationList.splice(index, 1);
                          setEventData({ ...eventData, locationList: locationList });
                        }}>
                          <DeleteIcon />
                        </i>
                      </div>
                    </div>
                  ))}
                </>
              } */}
            {/* <div className="py-3"><hr /></div> */}
            {/* <h2>Vehicles</h2>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Show Vehicles?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        showVehicles: e.target.checked,
                      });
                    }}
                    id="showVehicles"
                    name="showVehicles"
                    checked={eventData?.showVehicles || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.showVehicles &&
                <>
                  <div className="alert alert-danger">Coming Soon</div>
                  <div className="text-end">
                    <button
                      className={"btn btn-theme"}
                      onClick={() => {
                        setEventData({
                          ...eventData,
                          vehicleList: [...eventData?.vehicleList, {}],
                        });
                      }}
                    >
                      + VEHICLE
                    </button>
                  </div>
                  <div className="py-3"></div>
                  {eventData?.vehicleList?.map((cta, index) => (
                    <div key={index} className={index % 2 == 0 ? "alt-row cta-list-item" : "cta-list-item"}>
                      <div className={"form-group"}>
                        <label
                          className="form-label form-help"
                          onClick={() => {
                            setHelp({ ...help, vehicleTitle: !help.vehicleTitle });
                          }}
                        >
                          Vehicle #{index + 1} Title{" "}
                          <i className="xs">
                            <InfoIcon />
                          </i>
                        </label>
                        <div className={help.name ? "fs-xs" : "d-none"}>
                          Title for Vehicle #{index + 1}.
                        </div>
                        <input
                          type="text"
                          id={"vehicleTitle" + index}
                          name="vehicleTitle"
                          value={eventData?.vehicleList[index].vehicleTitle || ''}
                          onChange={(e) => { handleListChange(e, index, 'vehicleList') }}
                          className="form-control"
                        />
                      </div>
                      <div className={"form-group"}>
                        <label
                          className="form-label form-help"
                          onClick={() => {
                            setHelp({ ...help, vehicleDescription: !help.vehicleDescription });
                          }}
                        >
                          Vehicle Description #{index + 1}{" "}
                          <i className="xs">
                            <InfoIcon />
                          </i>
                        </label>
                        <div className={help.name ? "fs-xs" : "d-none"}>
                          Description for Vehicle #{index + 1}.
                        </div>
                        <input
                          type="text"
                          id={"vehicleDescription" + index}
                          name="vehicleDescription"
                          value={eventData?.vehicleList[index].vehicleDescription || ''}
                          onChange={(e) => { handleListChange(e, index, 'vehicleList') }}
                          className="form-control"
                        />
                      </div>
                      <div className="text-end">
                        <i className="del-icon ml-2" onClick={(e) => {
                          removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_vehicleList_" + index, null);
                          let vehicleList = eventData?.vehicleList;
                          vehicleList.splice(index, 1);
                          setEventData({ ...eventData, vehicleList: vehicleList });
                        }}>
                          <DeleteIcon />
                        </i>
                      </div>
                    </div>
                  ))}
                </>
              } */}
            <div className="py-3"><hr /></div>
            <h2>Call To Action</h2>
            <div className="form-group">
              <label className="form-label">Call To Action Layout</label>
              <select
                className="form-control"
                id="ctaLayout"
                name="ctaLayout"
                onChange={handleChange}
                value={eventData?.ctaLayout}
              >
                <option value="">Select a CTA Layout</option>
                <option value="NoLayout">No Call To Action</option>
                <option value="OnlyButtons">Only Buttons</option>
                {/* <option value="FullTiles">Full Tiles</option> */}
                <option value="SpacedTiles">Spaced Tiles</option>
                <option value="AlternatingRows">Alternating Rows</option>
                <option value="FullRows">Full Rows</option>
              </select>
              {eventData?.ctaLayout && eventData?.ctaLayout === "AlternatingRows" ? (
                <>
                  <label className="form-label mt-2">Alternating Row Image Ratio</label>
                  <select
                    className="form-control"
                    id="alternatingRowRatio"
                    name="alternatingRowRatio"
                    onChange={handleChange}
                    value={eventData?.alternatingRowRatio}
                  >
                    <option value="">Select an Alternating Row Image Ratio</option>
                    <option value="oneThird">1/3</option>
                    <option value="oneHalf">1/2</option>
                    <option value="twoThirds">2/3</option>
                  </select>
                </>
              ) : (<div></div>)}
              {eventData?.ctaLayout && eventData?.ctaLayout === "SpacedTiles" ? (
                <>
                  <label className="form-label mt-2">Tiles Per Row</label>
                  <select
                    className="form-control"
                    id="tilesPerRow"
                    name="tilesPerRow"
                    onChange={handleChange}
                    value={eventData?.tilesPerRow}
                  >
                    <option value="">Select Tiles Per Row</option>
                    <option value="three">3</option>
                    <option value="four">4</option>
                  </select>
                </>
              ) : (<div></div>)}
            </div>
            {eventData?.ctaLayout && eventData?.ctaLayout != "NoLayout" ? (
              <div className="cta-container">
                <div className="text-end">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      if (eventData?.ctaList) {
                        setEventData({
                          ...eventData,
                          ctaList: [...eventData?.ctaList, {}],
                        });
                      } else {
                        setEventData({
                          ...eventData,
                          ctaList: [],
                        });
                      }
                    }}
                  >
                    + CTA
                  </button>
                </div>
                <div className="py-3"></div>
                {eventData?.ctaList?.map((cta, index) => (
                  <>
                    <EventCTAItem item={cta} key={index} id={index} index={index} moveCard={moveCard} eventData={eventData} setEventData={setEventData} />
                  </>
                ))}
                <div className="py-2"></div>
              </div>
            ) : (<div></div>)}
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show Contact Us Button?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      showContactUs: e.target.checked,
                    });
                  }}
                  id="showContactUs"
                  name="showContactUs"
                  checked={eventData?.showContactUs || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="py-3"><hr /></div>
            <h2>Image Carousel w/ Lightbox</h2>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show Lightbox Carousel?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      showLightbox: e.target.checked,
                    });
                  }}
                  id="showLightbox"
                  name="showLightbox"
                  checked={eventData?.showLightbox || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.showLightbox &&
              <>
                <div className="text-end">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      setEventData({
                        ...eventData,
                        galleryImages: [...eventData?.galleryImages, ""],
                      });
                    }}
                  >
                    + Image
                  </button>
                </div>
                <div className="py-2"></div>
                <div className="row">
                  {eventData?.galleryImages?.map((img, index) => (
                    <div key={index} className="col-lg-3 col-md-4 col-6">
                      <div className="gallery-image-container">
                        <div className="form-group">
                          <label className="form-label">Gallery Image (1200x?)</label>
                          <div>
                            <input
                              id={"originalFileName" + index}
                              type="file"
                              name="originalFileName"
                              onChange={(e) => handleGalleryImageRead(e, index)}
                            />
                          </div>
                        </div>
                        <div className={img ? "" : "d-none"}>
                          <img id={"galleryImage" + index}
                            className="gallery-image w-100"
                            src={img}
                          />
                        </div>
                        <div className="text-end">
                          <i className="del-icon ml-2" onClick={(e) => {
                            removeCloudinary("pcna/ezentrum/" + eventData?.id + "/" + eventData?.id + "_galleryImage_" + index, null);
                            let galleryImages = eventData?.galleryImages;
                            galleryImages.splice(index, 1);
                            setEventData({ ...eventData, galleryImages: galleryImages });
                          }}>
                            <DeleteIcon />
                          </i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            }
            <div className="py-3"><hr /></div>
            <h2>Videos</h2>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Show Videos?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      showVideos: e.target.checked,
                    });
                  }}
                  id="showVideos"
                  name="showVideos"
                  checked={eventData?.showVideos || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.showVideos &&
              <>
                <div className="text-end">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      setEventData({
                        ...eventData,
                        videoList: [...eventData?.videoList, {}],
                      });
                    }}
                  >
                    + VIDEO
                  </button>
                </div>
                <div className="py-3"></div>
                {eventData?.videoList?.map((cta, index) => (
                  <div key={index} className={index % 2 == 0 ? "alt-row cta-list-item" : "cta-list-item"}>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, featuredVideoTitle: !help.featuredVideoTitle });
                        }}
                      >
                        Video #{index + 1} Title{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.featuredVideoTitle ? "fs-xs" : "d-none"}>
                        Vimeo link for featured or sizzle reel video #{index + 1}.
                      </div>
                      <input
                        type="text"
                        id={"featuredVideoTitle" + index}
                        name="featuredVideoTitle"
                        value={eventData?.videoList[index].featuredVideoTitle || ''}
                        onChange={(e) => { handleListChange(e, index, 'videoList') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, featuredVideo: !help.featuredVideo });
                        }}
                      >
                        Featured Video #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.featuredVideo ? "fs-xs" : "d-none"}>
                        Vimeo link for featured or sizzle reel video #{index + 1}.
                      </div>
                      <input
                        type="text"
                        id={"featuredVideo" + index}
                        name="featuredVideo"
                        value={eventData?.videoList[index].featuredVideo || ''}
                        onChange={(e) => { handleListChange(e, index, 'videoList') }}
                        className="form-control"
                      />
                    </div>
                    <div className="text-end">
                      <i className="del-icon ml-2" onClick={(e) => {
                        let videoList = eventData?.videoList;
                        videoList.splice(index, 1);
                        setEventData({ ...eventData, videoList: videoList });
                      }}>
                        <DeleteIcon />
                      </i>
                    </div>
                  </div>
                ))}
              </>
            }
            <div className="py-3"><hr /></div>
            <h2>Story Stream</h2>
            <div className={"form-group"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, storyStreamTitle: !help.storyStreamTitle });
                }}
              >
                Story Stream Title{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.storyStreamTitle ? "fs-xs" : "d-none"}>
                Title of the story stream.
              </div>
              <input
                type="text"
                id="storyStreamTitle"
                name="storyStreamTitle"
                value={eventData?.storyStreamTitle || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={"form-group"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, storyStreamSubtitle: !help.storyStreamSubtitle });
                }}
              >
                Story Stream Subtitle{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.storyStreamSubtitle ? "fs-xs" : "d-none"}>
                Typically the hashtag for the program.
              </div>
              <input
                type="text"
                id="storyStreamSubtitle"
                name="storyStreamSubtitle"
                value={eventData?.storyStreamSubtitle || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={"form-group"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, storyStream: !help.storyStream });
                }}
              >
                Story Stream{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.storyStream ? "fs-xs" : "d-none"}>
                Link to embed story stream into landing page for the event.
              </div>
              <input
                type="text"
                id="storyStream"
                name="storyStream"
                value={eventData?.storyStream || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={!eventData?.disableRegistration ? "form-group" : "d-none"}>
              <label>Pending Confirmation Copy</label>
              <div className="copy-container">
                <div className={eventData?.pendingConfirmationCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.pendingConfirmationCopy
                    ),
                  }}
                ></div>
                <button className="btn btn-default" onClick={() => { setEditCopy("pendingConfirmationCopy"); }}>Edit Pending Confirmation Copy</button>
              </div>
            </div>
            <div className={!eventData?.disableRegistration ? "form-group" : "d-none"}>
              <label>Confirmation Copy</label>
              <div className="copy-container">
                <div className={eventData?.confirmationCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.confirmationCopy
                    ),
                  }}
                ></div>
                <button className="btn btn-default" onClick={() => { setEditCopy("confirmationCopy"); }}>Edit Confirmation Copy</button>
              </div>
            </div>
            <div className={!eventData?.disableRegistration && eventData?.allowWaitlist ? "form-group" : "d-none"}>
              <label>Waitlist Copy</label>
              <div className="copy-container">
                <div className={eventData?.waitlistCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.waitlistCopy
                    ),
                  }}
                ></div>
                <button className="btn btn-default" onClick={() => { setEditCopy("waitlistCopy"); }}>Edit Waitlist Copy</button>
              </div>
            </div>
            {eventData?.allowFAQ &&
              <div className="form-group">
                <h2>FAQ</h2>
                <label>FAQ Copy</label>
                <div className="copy-container">
                  <div className={eventData?.faqCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.faqCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("faqCopy"); }}>Edit FAQ Copy</button>
                </div>
                <div className="text-end">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      let tmpFaqCategories = eventData?.faqCategories ? eventData?.faqCategories : [];
                      tmpFaqCategories.push({ catName: '', faqs: [] });
                      setEventData({
                        ...eventData,
                        faqCategories: tmpFaqCategories,
                      });
                    }}
                  >
                    + FAQ Category
                  </button>
                </div>
                <div className="py-2"></div>
                <div className="row">
                  {eventData?.faqCategories?.map((c, index) => (
                    <div key={index} className={index % 2 == 0 ? "alt-row col-12" : "col-12"}>
                      <div className="faq-category">
                        <div className="form-group">
                          <label className="form-label">Category Name</label>
                          <div>
                            <input
                              id={"catName-" + index}
                              type="text"
                              name="catName"
                              value={c.catName}
                              className={"form-control"}
                              onChange={(e) => {
                                let tmpFaqCategories = eventData?.faqCategories;
                                tmpFaqCategories[index].catName = e.target.value;
                                setEventData({
                                  ...eventData,
                                  faqCategories: tmpFaqCategories,
                                });
                              }}
                            />
                          </div>
                          <div className={"form-group pt-3"}>
                            <label className="checkbox-container">
                              Category Full Row
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  let tmpFaqCategories = eventData?.faqCategories;
                                  tmpFaqCategories[index].catFullRow = e.target.checked;
                                  setEventData({
                                    ...eventData,
                                    faqCategories: tmpFaqCategories,
                                  });
                                }}
                                id={"catFullRow" + index}
                                name="catFullRow"
                                checked={eventData?.faqCategories[index].catFullRow || false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <hr />
                        <div className="text-end">
                          <button
                            className={"btn btn-theme"}
                            onClick={() => {
                              let tmpFaqs = c.faqs ? c.faqs : [];
                              tmpFaqs.push({ question: '', answer: '', qFullRow: c.catFullRow ? false : true });
                              let tmpFaqCategories = eventData?.faqCategories;
                              tmpFaqCategories[index].faqs = tmpFaqs;
                              setEventData({
                                ...eventData,
                                faqCategories: tmpFaqCategories,
                              });
                            }}
                          >
                            + FAQ Question
                          </button>
                        </div>
                        {c?.faqs?.map((q, i) => (
                          <div key={i} className={i % 2 == 0 ? "alt-row col-12" : "col-12"}>
                            <div className="faq-question">
                              <div className="form-group">
                                <label className="form-label">Question</label>
                                <div>
                                  <input
                                    id={"catName-" + index + "-question-" + i}
                                    type="text"
                                    name="faqQuestion"
                                    value={q.question}
                                    className={"form-control"}
                                    onChange={(e) => {
                                      let tmpFaqCategories = eventData?.faqCategories;
                                      tmpFaqCategories[index].faqs[i].question = e.target.value;
                                      setEventData({
                                        ...eventData,
                                        faqCategories: tmpFaqCategories,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-label">Answer</label>
                                <div>
                                  <textarea rows="10" className="form-control" value={q.answer} onChange={(e) => {
                                    let tmpFaqCategories = eventData?.faqCategories;
                                    tmpFaqCategories[index].faqs[i].answer = e.target.value;
                                    setEventData({
                                      ...eventData,
                                      faqCategories: tmpFaqCategories,
                                    });
                                  }}></textarea>
                                </div>
                              </div>
                              <div className={"form-group"}>
                                <label className="checkbox-container">
                                  Question Full Row
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      let tmpFaqCategories = eventData?.faqCategories;
                                      tmpFaqCategories[index].faqs[i].qFullRow = e.target.checked;
                                      setEventData({
                                        ...eventData,
                                        faqCategories: tmpFaqCategories,
                                      });
                                    }}
                                    id={"qFullRow" + i}
                                    name="qFullRow"
                                    checked={q?.qFullRow || false}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="text-end">
                                <i className="del-icon ml-2" onClick={(e) => {
                                  let tmpFaqCategories = eventData?.faqCategories;
                                  tmpFaqCategories[index].faqs.splice(i, 1);
                                  setEventData({ ...eventData, faqCategories: tmpFaqCategories });
                                }}>
                                  <DeleteIcon />
                                </i> FAQ
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="py-3"><hr /></div>
                        <div className="text-end">
                          <i className="del-icon ml-2" onClick={(e) => {
                            let tmpFaqCategories = eventData?.faqCategories;
                            tmpFaqCategories.splice(index, 1);
                            setEventData({ ...eventData, faqCategories: tmpFaqCategories });
                          }}>
                            <DeleteIcon />
                          </i> FAQ Category
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
            {eventData?.allowGallery && !eventData?.disableRegistration &&
              <div className="form-group" >
                <label>Gallery Copy</label>
                <div className="copy-container">
                  <div className={eventData?.galleryCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.galleryCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("galleryCopy"); }}>Edit Gallery Copy</button>
                </div>
              </div>
            }
            {eventData?.allowTerms && !eventData?.disableRegistration &&
              <div className="form-group" >
                <label>Terms & Conditions Copy</label>
                <div className="copy-container">
                  <div className={eventData?.termsCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.termsCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("termsCopy"); }}>Edit Terms Copy</button>
                </div>
              </div>
            }
          </div>
        }
      </div>
      <div className="card mb-4">
        <div className="card-header" onClick={() => { updateShowModules('notifications', !showModules.notifications) }}>
          <h2>Event Notifications</h2>
          <div>
            <span className={showModules.notifications ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.notifications ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.notifications &&
          <div className="card-body">
            {!eventData?.disableRegistration ? (<>
              <div className="form-group">
                <label className="form-label">Support Email</label>
                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={eventData?.email}
                  >
                    <option value=""></option>
                    {settings.mailboxes.map((mailbox, index) => (
                      <option key={index} value={mailbox}>
                        {mailbox}
                      </option>
                    ))}
                  </select>
                  <span className="input-group-text" id="basic-addon">
                    @porsche-ezentrum.us
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Bcc Admin Emails (comma separated addresses)</label>
                <input
                  type="text"
                  id="bccEmail"
                  name="bccEmail"
                  value={eventData?.bccEmail || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Email From</label>
                <input
                  type="text"
                  id="emailFrom"
                  name="emailFrom"
                  value={eventData?.emailFrom || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Reply To</label>
                <input
                  type="text"
                  id="replyTo"
                  name="replyTo"
                  value={eventData?.replyTo || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Email Campaign Code</label>
                <input
                  type="text"
                  id="emailCampaignCode"
                  name="emailCampaignCode"
                  value={eventData?.emailCampaignCode || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Email Banner Image (1600x580)</label>
                <div>
                  <input
                    id="originalEmailFileName"
                    type="file"
                    name="originalEmailFileName"
                    onChange={(e) => handleEmailFileRead(e)}
                  />
                </div>
              </div>
              <div className={eventData?.emailBannerImage ? "" : "d-none"}>
                <img id="emailBannerImage"
                  className="desktop-image"
                  src={eventData?.emailBannerImage}
                  alt={eventData?.name}
                />
                <div>
                  <button className={"btn btn-link"} onClick={removeEmailBannerImage}>Remove Image</button>
                </div>
              </div>
              <div className="py-2"></div>
              <div className="form-group">
                <label className="form-label">Mobile Email Banner Image (800x600)</label>
                <div>
                  <input
                    id="originalMobileEmailFileName"
                    type="file"
                    name="originalMobileEmailFileName"
                    onChange={(e) => handleMobileEmailFileRead(e)}
                  />
                </div>
              </div>
              <div className={eventData?.mobileEmailBannerImage ? "banner-image mobile-banner" : "d-none"}>
                <img
                  className="mobile-image"
                  id="mobileEmailBannerImage"
                  src={eventData?.mobileEmailBannerImage}
                  alt={eventData?.name}
                />
                <div>
                  <button className={"btn btn-link"} onClick={removeMobileEmailBannerImage}>Remove Image</button>
                </div>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Send confirmation {eventData?.allowWaitlist ? "/ waitlist" : ""} email.
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowConfirmationEmail: e.target.checked,
                      });
                    }}
                    id="allowConfirmationEmail"
                    name="allowConfirmationEmail"
                    checked={eventData?.allowConfirmationEmail || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={"form-group"}>
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, pendingEmailSubject: !help.pendingEmailSubject });
                  }}
                >
                  Pending Email Subject{" "}
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.pendingEmailSubject ? "fs-xs" : "d-none"}>
                  Pending Email Subject
                </div>
                <input
                  type="text"
                  id="pendingEmailSubject"
                  name="pendingEmailSubject"
                  value={eventData?.pendingEmailSubject || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group" >
                <label>Pending Email Copy</label>
                <div className="copy-container">
                  <div className={eventData?.pendingEmailCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.pendingEmailCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("pendingEmailCopy"); }}>Edit Pending Email Copy</button>
                </div>
              </div>
              <div className="form-group">
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, confirmationEmailSubject: !help.confirmationEmailSubject });
                  }}
                >
                  Confirmation Email Subject{" "}
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.confirmationEmailSubject ? "fs-xs" : "d-none"}>
                  Confirmation Email Subject
                </div>
                <input
                  type="text"
                  id="confirmationEmailSubject"
                  name="confirmationEmailSubject"
                  value={eventData?.confirmationEmailSubject || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Confirmation Email Copy</label>
                <div className="copy-container">
                  <div className={eventData?.confirmationEmailCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.confirmationEmailCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("confirmationEmailCopy"); }}>Edit Confirmation Email Copy</button>
                </div>
              </div>
              {eventData?.allowWaitlist ? (
                <div className={"form-group"}>
                  <label
                    className="form-label form-help"
                    onClick={() => {
                      setHelp({ ...help, waitlistEmailSubject: !help.waitlistEmailSubject });
                    }}
                  >
                    Waitlist Email Subject{" "}
                    <i className="xs">
                      <InfoIcon />
                    </i>
                  </label>
                  <div className={help.waitlistEmailSubject ? "fs-xs" : "d-none"}>
                    Waitlist Email Subject
                  </div>
                  <input
                    type="text"
                    id="waitlistEmailSubject"
                    name="waitlistEmailSubject"
                    value={eventData?.waitlistEmailSubject || ''}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              ) : (<div></div>)}
              <div className={eventData?.allowConfirmationEmail && eventData?.allowWaitlist ? "form-group" : "d-none"}>
                <label>Waitlist Email Copy</label>
                <div className="copy-container">
                  <div className={eventData?.waitlistEmailCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.waitlistEmailCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("waitlistEmailCopy"); }}>Edit Waitlist Email Copy</button>
                </div>
              </div>
              <div className={"form-group"}>
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, reminderEmailSubject: !help.reminderEmailSubject });
                  }}
                >
                  Reminder Email Subject{" "}
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.reminderEmailSubject ? "fs-xs" : "d-none"}>
                  Reminder Email Subject
                </div>
                <input
                  type="text"
                  id="reminderEmailSubject"
                  name="reminderEmailSubject"
                  value={eventData?.reminderEmailSubject || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Reminder Email Copy</label>
                <div className="copy-container">
                  <div className={eventData?.reminderEmailCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.reminderEmailCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("reminderEmailCopy"); }}>Edit Reminder Email Copy</button>
                </div>
              </div>
              <div className={"form-group"}>
                <label
                  className="form-label form-help"
                  onClick={() => {
                    setHelp({ ...help, cancellationEmailSubject: !help.cancellationEmailSubject });
                  }}
                >
                  Cancellation Email Subject{" "}
                  <i className="xs">
                    <InfoIcon />
                  </i>
                </label>
                <div className={help.cancellationEmailSubject ? "fs-xs" : "d-none"}>
                  Cancellation Email Subject
                </div>
                <input
                  type="text"
                  id="cancellationEmailSubject"
                  name="cancellationEmailSubject"
                  value={eventData?.cancellationEmailSubject || ''}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Cancellation Email Copy</label>
                <div className="copy-container">
                  <div className={eventData?.cancellationEmailCopy ? "mb-3 p-2 edit-copy" : "d-none"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        eventData?.cancellationEmailCopy
                      ),
                    }}
                  ></div>
                  <button className="btn btn-default" onClick={() => { setEditCopy("cancellationEmailCopy"); }}>Edit Cancellation Email Copy</button>
                </div>
              </div>
            </>
            ) : (<></>)}
          </div>
        }
      </div>
      <div className="card mb-4">
        <div className="card-header" onClick={() => { updateShowModules('configuration', !showModules.configuration) }}>
          <h2>Registration Configuration</h2>
          <div>
            <span className={showModules.configuration ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.configuration ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.configuration &&
          <div className="card-body">
            <div className={"form-group"}>
              <label className="checkbox-container">
                External / No Registration Required?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      disableRegistration: e.target.checked,
                    });
                  }}
                  id="disableRegistration"
                  name="disableRegistration"
                  checked={eventData?.disableRegistration || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {!eventData?.disableRegistration && <>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Disable Front End Registration
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        disableFrontEndRegistration: e.target.checked,
                      });
                    }}
                    id="disableFrontEndRegistration"
                    name="disableFrontEndRegistration"
                    checked={eventData?.disableFrontEndRegistration || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.allowWaitlist &&
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Force Event Full / Waitlist Only
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          forceEventFull: e.target.checked,
                        });
                      }}
                      id="forceEventFull"
                      name="forceEventFull"
                      checked={eventData?.forceEventFull || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              }
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Allow registration lookup / edit?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowLookup: e.target.checked,
                      });
                    }}
                    id="allowLookup"
                    name="allowLookup"
                    checked={eventData?.allowLookup || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  QR Code direct to edit instead of check-in page?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        qrCodeToEdit: e.target.checked,
                      });
                    }}
                    id="qrCodeToEdit"
                    name="qrCodeToEdit"
                    checked={eventData?.qrCodeToEdit || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Front-End registrations require unique email.
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowUniqueEmail: e.target.checked,
                      });
                    }}
                    id="allowUniqueEmail"
                    name="allowUniqueEmail"
                    checked={eventData?.allowUniqueEmail || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {/* <div className={"form-group"}>
                <label className="checkbox-container">
                  Use Address Validation
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowAddressValidation: e.target.checked,
                      });
                    }}
                    id="allowAddressValidation"
                    name="allowAddressValidation"
                    checked={eventData?.allowAddressValidation || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div> */}
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Enable event waitlist?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowWaitlist: e.target.checked,
                      });
                    }}
                    id="allowWaitlist"
                    name="allowWaitlist"
                    checked={eventData?.allowWaitlist}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Require Waivers during registration.
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      console.log(eventData?.allowWaivers);
                      setEventData({
                        ...eventData,
                        allowWaivers: e.target.checked,
                      });
                    }}
                    id="allowWaivers"
                    name="allowWaivers"
                    checked={eventData?.allowWaivers || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.allowGuests && eventData?.allowWaivers &&
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Require Guest Waivers during registration.
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        console.log(eventData?.allowGuestWaivers);
                        setEventData({
                          ...eventData,
                          allowGuestWaivers: e.target.checked,
                        });
                      }}
                      id="allowGuestWaivers"
                      name="allowGuestWaivers"
                      checked={eventData?.allowGuestWaivers || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              }
              <div className={eventData?.allowWaivers && !eventData?.disableRegistration ? "form-group" : "d-none"}>
                <label className="form-label">Waiver</label>
                <select
                  className="form-control"
                  id="waiver"
                  name="waiver"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      waiverIndex: e.target.value,
                      waiverCopy:
                        settings.waiverDefaultCopy?.length > 0
                          ? settings.waiverDefaultCopy[e.target.value]
                          : null,
                    });
                  }}
                  value={eventData?.waiverIndex}
                >
                  <option value=""></option>
                  {settings.waiverDefaultCopy.map((waiver, index) => (
                    <option key={index} value={index}>
                      {waiver.name}
                    </option>
                  ))}
                </select>
              </div>
              {eventData?.allowWaivers && eventData?.waiverCopy &&
                <div className="fs-xs pb-3">
                  {!showWaiver && <span className="underline pointer" onClick={() => { setShowWaiver(true) }}>Show Waiver</span>}
                  {showWaiver && <span className="underline pointer" onClick={() => { setShowWaiver(false) }}>Hide Waiver</span>}
                </div>
              }
              <div
                className={
                  eventData?.allowWaivers && eventData?.waiverCopy && showWaiver ? "" : "d-none"
                }
              >
                <div className={!eventData?.disableRegistration ? "" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.waiverCopy?.content
                    ),
                  }}
                ></div>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Opt-In must be checked to continue / register?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        allowOptInVerify: e.target.checked,
                      });
                    }}
                    id="allowOptInVerify"
                    name="allowOptInVerify"
                    checked={eventData?.allowOptInVerify || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Opt-In below Submit Button?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        optInBelow: e.target.checked,
                      });
                    }}
                    id="optInBelow"
                    name="optInBelow"
                    checked={eventData?.optInBelow || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={!eventData?.disableRegistration ? "form-group" : "d-none"}>
                <label className="form-label">Opt-In Language</label>
                <select
                  className="form-control"
                  id="optIn"
                  name="optIn"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      optInIndex: e.target.value,
                      optInCopy:
                        settings.optInDefaultCopy?.length > 0
                          ? settings.optInDefaultCopy[e.target.value]
                          : null,
                    });
                  }}
                  value={eventData?.optInIndex}
                >
                  <option value=""></option>
                  {settings.optInDefaultCopy.map((optIn, index) => (
                    <option key={index} value={index}>
                      {optIn.name}
                    </option>
                  ))}
                </select>
              </div>
              {eventData?.optInCopy &&
                <div className="fs-xs pb-3">
                  {!showOptIn && <span className="underline pointer" onClick={() => { setShowOptIn(true) }}>Show Opt-In</span>}
                  {showOptIn && <span className="underline pointer" onClick={() => { setShowOptIn(false) }}>Hide Opt-In</span>}
                </div>
              }
              <div className={eventData?.optInCopy && showOptIn ? "" : "d-none"}>
                <div className={!eventData?.disableRegistration ? "" : "d-none"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      eventData?.optInCopy?.content
                    ),
                  }}
                ></div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  Default Registration Status
                </label>
                <select
                  className="form-control"
                  id="defaultRegStatus"
                  name="defaultRegStatus"
                  onChange={handleChange}
                  value={eventData?.defaultRegStatus}
                >
                  <option value="Registered">Registered</option>
                  <option value="Pending">Pending</option>
                  <option value="Waitlist">Waitlist</option>
                  <option value="Checked-In">Checked-In</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <h3>Registration Alerts</h3>
              <div className="form-group">
                <label className="form-label">Red Alert</label>
                <select
                  className="form-control red-alert"
                  id="redAlert"
                  name="redAlert"
                  onChange={handleChange}
                  value={eventData?.redAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Yellow Alert</label>
                <select
                  className="form-control yellow-alert"
                  id="yellowAlert"
                  name="yellowAlert"
                  onChange={handleChange}
                  value={eventData?.yellowAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Orange Alert</label>
                <select
                  className="form-control orange-alert"
                  id="orangeAlert"
                  name="orangeAlert"
                  onChange={handleChange}
                  value={eventData?.orangeAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Purple Alert</label>
                <select
                  className="form-control purple-alert"
                  id="purpleAlert"
                  name="purpleAlert"
                  onChange={handleChange}
                  value={eventData?.purpleAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Blue Alert</label>
                <select
                  className="form-control blue-alert"
                  id="blueAlert"
                  name="blueAlert"
                  onChange={handleChange}
                  value={eventData?.blueAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Green Alert</label>
                <select
                  className="form-control green-alert"
                  id="greenAlert"
                  name="greenAlert"
                  onChange={handleChange}
                  value={eventData?.greenAlert}
                >
                  <option value=""></option>
                  {alertRules?.map((r, index) => (
                    <option key={index} value={r}>{r}</option>
                  ))}
                </select>
              </div>
            </>}
          </div>
        }
      </div>
      <div className={!eventData?.disableRegistration ? "card mb-4" : "d-none"}>
        <div className="card-header" onClick={() => { updateShowModules('passcodes', !showModules.passcodes) }}>
          <h2>Passcodes</h2>
          <div>
            <span className={showModules.passcodes ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.passcodes ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.passcodes &&
          <div className="card-body">
            <div className={"form-group"}>
              <label className="checkbox-container">
                Use Passcodes?
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowPasscodes: e.target.checked,
                    });
                  }}
                  id="allowPasscodes"
                  name="allowPasscodes"
                  value="true"
                  checked={eventData?.allowPasscodes || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.allowPasscodes && <>
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Use Multi-Use Passcodes?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (!e.target.checked || (e.target.checked && !eventData?.allowSingleUsePasscodes)) {
                        setEventData({
                          ...eventData,
                          allowMultiUsePasscodes: e.target.checked,
                        });
                      } else {
                        alert('Disable single-use passcodes before enabling this feature.');
                      }
                    }}
                    id="allowMultiUsePasscodes"
                    name="allowMultiUsePasscodes"
                    value="true"
                    checked={eventData?.allowMultiUsePasscodes || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.allowMultiUsePasscodes && <>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Require Multi-Use Passcodes (Admin)?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked && user.email.indexOf("core12.com") === -1) {
                          alert('Contact a Core12 Admin to enable this feature');
                        } else {
                          setEventData({
                            ...eventData,
                            requireMultiUsePasscodes: e.target.checked,
                          });
                        }
                      }}
                      id="requireMultiUsePasscodes"
                      name="requireMultiUsePasscodes"
                      value="true"
                      checked={eventData?.requireMultiUsePasscodes || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <h3>Multi-Use Passcodes</h3>
                <div className="passcode-container">
                  <div className="text-end">
                    <button
                      className={"btn btn-theme"}
                      onClick={() => {
                        // setPasscodeUserRefs([...passcodeUserRefs, React.createRef()]);
                        if (eventData?.multiUsePasscodes) {
                          setEventData({
                            ...eventData,
                            multiUsePasscodes: [...eventData?.multiUsePasscodes, {}],
                          });
                        } else {
                          setEventData({
                            ...eventData,
                            multiUsePasscodes: [],
                          });
                        }
                      }}
                    >
                      + Multi-Use Passcode
                    </button>
                  </div>
                  <div className="py-3"></div>
                  {/* single = prefix and range | multi = code and allowed users */}
                  {eventData?.multiUsePasscodes?.map((pc, index) => (
                    <div key={index}>
                      <div className="row pb-2">
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Passcode</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'multiUsePasscodes') }}
                              id={"passcode" + index}
                              name="passcode"
                              className={"form-control"}
                              value={eventData?.multiUsePasscodes[index].passcode}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Label</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'multiUsePasscodes') }}
                              id={"label" + index}
                              name="label"
                              className={"form-control"}
                              value={eventData?.multiUsePasscodes[index].label}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'multiUsePasscodes') }}
                              id={"email" + index}
                              name="email"
                              className={"form-control"}
                              value={eventData?.multiUsePasscodes[index].email}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Details</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'multiUsePasscodes') }}
                              id={"details" + index}
                              name="details"
                              className={"form-control"}
                              value={eventData?.multiUsePasscodes[index].details}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'multiUsePasscodes') }}
                              id={"description" + index}
                              name="description"
                              className={"form-control"}
                              value={eventData?.multiUsePasscodes[index].description}
                            />
                          </div>
                        </div>
                        {eventData?.requireMultiUsePasscodes &&
                          <div className="col">
                            <div className="form-group">
                              <label>Allowed Users</label>
                              <Multiselect
                                options={userList}
                                style={multiSelectStyle}
                                displayValue="name"
                                // ref={passcodeUserRefs[index]}
                                selectedValues={eventData?.multiUsePasscodes[index]?.usersAllowed}
                                closeIcon="close"
                                // disable={!passcodeUserRefs[index]?.current}
                                onSelect={(selectedList, selectedItem) => {
                                  let tmpList = eventData["multiUsePasscodes"];
                                  tmpList[index] = { ...tmpList[index], usersAllowed: selectedList };
                                  setEventData({ ...eventData, multiUsePasscodes: tmpList });
                                }}
                                onRemove={(selectedList, removedItem) => {
                                  let tmpList = eventData["multiUsePasscodes"];
                                  tmpList[index] = { ...tmpList[index], usersAllowed: selectedList };
                                  setEventData({ ...eventData, multiUsePasscodes: tmpList });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label>Assigned Dealer</label>
                              <Select
                                value={dealerOptions.find(o => o.value === eventData?.multiUsePasscodes[index].dealer)}
                                onChange={(e) => {
                                  handleListChange({ target: { name: 'dealer' } }, index, 'multiUsePasscodes', false, e.value);
                                }}
                                options={dealerOptions}
                                required={
                                  eventData?.dataFields.dealer?.visible &&
                                    eventData?.dataFields.dealer?.required
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className={"form-group"}>
                              <label className="checkbox-container">
                                Disable All Leads to C@P?
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleListChange({ target: { name: 'disableAllLeads' } }, index, 'multiUsePasscodes', false, e.target.checked);
                                  }}
                                  id="disableAllLeads"
                                  name="disableAllLeads"
                                  value="true"
                                  checked={eventData?.multiUsePasscodes[index].disableAllLeads || false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="text-end pb-3">
                        <i className="del-icon ml-2" onClick={(e) => {
                          let tmpMultiUsePasscodes = eventData?.multiUsePasscodes;
                          tmpMultiUsePasscodes.splice(index, 1);
                          // let tmpPasscodeUserRefs = passcodeUserRefs;
                          // tmpPasscodeUserRefs.splice(index, 1);
                          // setPasscodeUserRefs(tmpPasscodeUserRefs);
                          setEventData({ ...eventData, multiUsePasscodes: tmpMultiUsePasscodes });
                        }}>
                          <DeleteIcon />
                        </i> Multi-Use Passcode
                      </div>
                    </div>
                  ))}
                  <div className="py-2"></div>
                </div>
              </>}
              <div className={"form-group"}>
                <label className="checkbox-container">
                  Use Single-Use Passcodes?
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked && user.email.indexOf("core12.com") === -1) {
                        alert('Contact a Core12 Admin to enable this feature');
                      } else if (!e.target.checked || (e.target.checked && !eventData?.allowMultiUsePasscodes)) {
                        setEventData({
                          ...eventData,
                          allowSingleUsePasscodes: e.target.checked,
                        });
                      } else {
                        alert('Disable multi-use passcodes before enabling this feature.');
                      }
                    }}
                    id="allowSingleUsePasscodes"
                    name="allowSingleUsePasscodes"
                    value="true"
                    checked={eventData?.allowSingleUsePasscodes || false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {eventData?.allowSingleUsePasscodes && <>
                <h3>Single-Use Passcodes</h3>
                <div className="passcode-container">
                  <div className="text-end">
                    <button
                      className={"btn btn-theme"}
                      onClick={() => {
                        if (eventData?.singleUsePasscodes) {
                          setEventData({
                            ...eventData,
                            singleUsePasscodes: [...eventData?.singleUsePasscodes, {}],
                          });
                        } else {
                          setEventData({
                            ...eventData,
                            singleUsePasscodes: [],
                          });
                        }
                      }}
                    >
                      + Single-Use Passcode
                    </button>
                  </div>
                  <div className="py-3"></div>
                  {/* single = prefix and range | multi = code and allowed users */}
                  {eventData?.singleUsePasscodes?.map((pc, index) => (
                    <div key={index}>
                      <div className="row pb-2 align-items-center">
                        <div className="col-3">
                          <div className="form-group">
                            <label className="form-label">Prefix</label>
                            <input
                              type="text"
                              onChange={(e) => { if (e.target.value.length <= 3) handleListChange(e, index, 'singleUsePasscodes') }}
                              id={"prefix" + index}
                              name="prefix"
                              className={"form-control"}
                              value={eventData?.singleUsePasscodes[index].prefix}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label className="form-label">Low</label>
                            <input
                              type="number"
                              onChange={(e) => { handleListChange(e, index, 'singleUsePasscodes') }}
                              id={"low" + index}
                              name="low"
                              className={"form-control"}
                              value={eventData?.singleUsePasscodes[index].low}
                              maxlength="3"
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label className="form-label">High</label>
                            <input
                              type="number"
                              onChange={(e) => { handleListChange(e, index, 'singleUsePasscodes') }}
                              id={"high" + index}
                              name="high"
                              className={"form-control"}
                              value={eventData?.singleUsePasscodes[index].high}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label className="checkbox-container">
                              Allow Auto Assign
                              <input
                                type="checkbox"
                                onChange={(e) => { handleListChange(e, index, 'singleUsePasscodes', true) }}
                                id={"autoAssign" + index}
                                name="autoAssign"
                                checked={eventData?.singleUsePasscodes[index].autoAssign || false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="checkbox-container">
                              Assigned Sessions
                              <input
                                type="checkbox"
                                onChange={(e) => { handleListChange(e, index, 'singleUsePasscodes', true) }}
                                id={"assignedSessions" + index}
                                name="assignedSessions"
                                checked={eventData?.singleUsePasscodes[index].assignedSessions || false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-end pb-3">
                        <i className="del-icon ml-2" onClick={(e) => {
                          let tmpSingleUsePasscodes = eventData?.singleUsePasscodes;
                          tmpSingleUsePasscodes.splice(index, 1);
                          setEventData({ ...eventData, singleUsePasscodes: tmpSingleUsePasscodes });
                        }}>
                          <DeleteIcon />
                        </i> Single-Use Passcode
                      </div>
                    </div>
                  ))}
                  <div className="py-2"></div>
                </div>
                <div><button className="btn btn-theme" onClick={(e) => { alert('contact support') }}>Download Passcodes</button></div>
              </>}
            </>}
          </div>
        }
      </div>
      <div className={!eventData?.disableRegistration ? "card mb-4" : "d-none"}>
        <div className="card-header" onClick={() => { updateShowModules('capacities', !showModules.capacities) }}>
          <h2>Capacities & Sessions</h2>
          <div>
            <span className={showModules.capacities ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.capacities ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.capacities &&
          <div className="card-body">
            <div className={!eventData?.disableRegistration ? "form-group" : "d-none"}>
              <label className="checkbox-container">
                Limit capacity at the event.
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowCapacity: e.target.checked,
                    });
                  }}
                  id="allowCapacity"
                  name="allowCapacity"
                  checked={eventData?.allowCapacity || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.allowCapacity && <>
              <div className="form-group">
                <label className="form-label">Capacity Type</label>
                <select
                  className="form-control"
                  id="capacityType"
                  name="capacityType"
                  onChange={(e) => {
                    if (e.target.value === "session" && user.email.indexOf("core12.com") === -1) {
                      alert('Contact a Core12 Admin to enable this feature');
                    } else {
                      setEventData({
                        ...eventData,
                        capacityType: e.target.value,
                      });
                    }
                  }}
                  value={eventData?.capacityType || ''}
                >
                  <option value="">Basic</option>
                  <option value="session">Session</option>
                </select>
              </div>
              {(!eventData?.capacityType || eventData?.capacityType == "") && <>
                <div className="form-group">
                  <label className="form-label">Capacity</label>
                  <input
                    type="number"
                    id="capacity"
                    name="capacity"
                    value={eventData?.capacity || 0}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Attendees can bring a guest?
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          allowGuests: e.target.checked,
                        });
                      }}
                      id="allowGuests"
                      name="allowGuests"
                      checked={eventData?.allowGuests || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {eventData?.allowGuests ? (<>
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Collect Guest Details
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            allowGuestDetails: e.target.checked,
                          });
                        }}
                        id="allowGuestDetails"
                        name="allowGuestDetails"
                        checked={eventData?.allowGuestDetails || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Collect Guest Waiver
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            allowGuestWaiver: e.target.checked,
                          });
                        }}
                        id="allowGuestWaiver"
                        name="allowGuestWaiver"
                        checked={eventData?.allowGuestWaiver || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Guests count towards capacity?
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            allowGuestCapacity: e.target.checked,
                          });
                        }}
                        id="allowGuestCapacity"
                        name="allowGuestCapacity"
                        checked={eventData?.allowGuestCapacity || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </>) : (<div></div>)}
              </>}
              {eventData?.capacityType == "session" && <>
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Attendees can bring a tag-along guest? (guests do not count against capacity)
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          allowGuests: e.target.checked,
                        });
                      }}
                      id="allowGuests"
                      name="allowGuests"
                      checked={eventData?.allowGuests || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {eventData?.allowGuests &&
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Collect Guest Waiver
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            allowGuestWaiver: e.target.checked,
                          });
                        }}
                        id="allowGuestWaiver"
                        name="allowGuestWaiver"
                        checked={eventData?.allowGuestWaiver || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                }
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Send SMS Reminders
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          allowSMSReminders: e.target.checked,
                        });
                      }}
                      id="allowSMSReminders"
                      name="allowSMSReminders"
                      checked={eventData?.allowSMSReminders || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {eventData?.allowSMSReminders &&
                  <>
                    <div className={"form-group"}>
                      <label className="form-label">SMS Reminder Message</label>
                      <textarea
                        id="smsReminderMessage"
                        name="smsReminderMessage"
                        onChange={handleChange}
                        className="form-control"
                        value={eventData?.smsReminderMessage || ''}
                      ></textarea>
                    </div>
                    <div className={"form-group"}>
                      <label className="form-label">Minutes Before Session Time</label>
                      <input
                        type="number"
                        id="smsReminderMinutes"
                        name="smsReminderMinutes"
                        onChange={handleChange}
                        className="form-control"
                        value={eventData?.smsReminderMinutes || ''}
                      />
                    </div>
                    <div className={"form-group"}>
                      <label className="form-label">Event Time Zone</label>
                      <select
                        id="smsReminderTimezone"
                        name="smsReminderTimezone"
                        onChange={handleChange}
                        className="form-control"
                        value={eventData?.smsReminderTimezone || ''}
                      >
                        <option value=""></option>
                        <option value="America/Chicago">America/Chicago (CST)</option>
                        <option value="America/New_York">America/New_York (EST)</option>
                        <option value="America/Los_Angeles">America/Los_Angeles (PST)</option>
                        <option value="America/Denver">America/Denver (MST)</option>

                      </select>
                      <div className="pt-3">Current Event Time: {moment().tz(eventData?.smsReminderTimezone)?.format("MM/DD/YYYY h:mm A")}</div>
                    </div>
                  </>
                }

                <h3>Session Items</h3>
                <div className="session-container">
                  <div className="text-end">
                    <button
                      className={"btn btn-theme"}
                      onClick={() => {
                        if (eventData?.sessionItems) {
                          setEventData({
                            ...eventData,
                            sessionItems: [...eventData?.sessionItems, {}],
                          });
                        } else {
                          setEventData({
                            ...eventData,
                            sessionItems: [],
                          });
                        }
                      }}
                    >
                      + Session Item
                    </button>
                  </div>
                  <div className="py-3"></div>
                  {/* single = prefix and range | multi = code and allowed users */}
                  {eventData?.sessionItems?.map((pc, index) => (
                    <div key={index}>
                      <div className="row pb-2">
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'sessionItems') }}
                              id={"name" + index}
                              name="name"
                              className={"form-control"}
                              value={eventData?.sessionItems[index].name}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Slug</label>
                            <input
                              type="text"
                              onChange={(e) => { handleListChange(e, index, 'sessionItems') }}
                              id={"slug" + index}
                              name="slug"
                              className={"form-control"}
                              value={eventData?.sessionItems[index].slug}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label className="form-label">Description</label>
                          <textarea
                            onChange={(e) => { handleListChange(e, index, 'sessionItems') }}
                            id={"description" + index}
                            name="description"
                            className={"form-control"}
                            rows="10"
                            value={eventData?.sessionItems[index].description}
                          ></textarea>
                        </div>
                      </div>
                      <div className="text-end pb-3">
                        <i className="del-icon ml-2" onClick={(e) => {
                          let tmpSessionItems = eventData?.sessionItems;
                          tmpSessionItems.splice(index, 1);
                          setEventData({ ...eventData, sessionItems: tmpSessionItems });
                        }}>
                          <DeleteIcon />
                        </i> Session Item
                      </div>
                    </div>
                  ))}
                  <div className="py-2"></div>
                  <h3>Sessions</h3>
                  <div className="form-group">
                    <label className="form-label">CSV Output</label>
                    <textarea
                      id="sessions"
                      name="sessions"
                      onChange={handleCSVChange}
                      className="form-control"
                      value=""
                    ></textarea>
                  </div>
                  {sessionData &&
                    <div className="fs-xs pb-3">
                      {!showSessions && <span className="underline pointer" onClick={() => { setShowSessions(true) }}>Show Sessions</span>}
                      {showSessions && <span className="underline pointer" onClick={() => { setShowSessions(false) }}>Hide Sessions</span>}
                    </div>
                  }
                  {sessionData && showSessions && <>
                    <div className="py-2"></div>
                    <JsonEditor
                      data={sessionData}
                    // setData={ (setJsonData) } // optional
                    // restrictDelete={true}
                    />
                    {/* {sessionData?.map((s, index) => (
                        <div className="row">
                          <div className="col-1">
                            {s.id}
                          </div>
                          <div className="col-3">
                            {eventData?.sessionItems[s.itemIndex]?.name}
                          </div>
                          <div className="col-4">
                            {s.startTime}
                          </div>
                          <div className="col-1">
                            {s.lockCreatedOn}
                          </div>
                          <div className="col-1">
                            {s.reserved}
                          </div>
                          <div className="col-2">
                            {s.passcode}
                            </div>
</div>
                    ))}  */}
                  </>
                  }
                  <div className="form-group">
                    <label
                      className="form-label form-help"
                      onClick={() => {
                        setHelp({ ...help, sessionLabel: !help.sessionLabel });
                      }}
                    >
                      Alternate Session Label {" "}
                      <i className="xs">
                        <InfoIcon />
                      </i>
                    </label>
                    <div className={help.submitButton ? "fs-xs" : "d-none"}>
                      Alternate session label instead of Select Date & Time
                    </div>
                    <input
                      type="text"
                      id="sessionLabel"
                      name="sessionLabel"
                      value={eventData?.sessionLabel || ''}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-label form-help"
                      onClick={() => {
                        setHelp({ ...help, sessionLabel: !help.sessionLabel });
                      }}
                    >
                      Days Ahead {" "}
                      <i className="xs">
                        <InfoIcon />
                      </i>
                    </label>
                    <div className={help.submitButton ? "fs-xs" : "d-none"}>
                      Days in advance that a session can be selected from the front end.
                    </div>
                    <input
                      type="number"
                      id="daysAhead"
                      name="daysAhead"
                      value={eventData?.daysAhead || ''}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="checkbox-container">
                      Hide Session Times
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            hideSessionTimes: e.target.checked,
                          });
                        }}
                        id="hideSessionTimes"
                        name="hideSessionTimes"
                        checked={eventData?.hideSessionTimes || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="checkbox-container">
                      Condensed Sessions
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            condensedSessions: e.target.checked,
                          });
                        }}
                        id="condensedSessions"
                        name="condensedSessions"
                        checked={eventData?.condensedSessions || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="py-2"></div>
                </div>
              </>}
            </>}
          </div>
        }
      </div>
      <div className={!eventData?.disableRegistration ? "card mb-4" : "d-none"}>
        <div className="card-header" onClick={() => { updateShowModules('leads', !showModules.leads) }}>
          <h2>C@P Lead Processing</h2>
          <div>
            <span className={showModules.leads ? "active pe-3" : "pe-3"}>Show</span>
            <span className={!showModules.leads ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.leads &&
          <div className="card-body">
            <div className={"form-group"}>
              <label className="checkbox-container">
                Send Event Data to C@P
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (user.email.indexOf("core12.com") > -1) {
                      setEventData({
                        ...eventData,
                        allowPushToCap: e.target.checked,
                      });
                    } else {
                      alert('Contact a Core12 Admin to enable this feature');
                    }
                  }}
                  id="allowPushToCap"
                  name="allowPushToCap"
                  checked={eventData?.allowPushToCap || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {eventData?.allowPushToCap &&
              <>
                {!eventData?.campaignCode &&
                  <div>
                    <Multiselect
                      options={campaignCodes}
                      style={multiSelectStyle}
                      displayValue="label"
                      closeIcon="close"
                      selectionLimit={1}
                      onSelect={(selectedList, selectedItem) => {
                        console.log(selectedItem);
                        setEventData({
                          ...eventData,
                          campaignCode: selectedItem.value.leadCampaignCode,
                          campaignDescription: selectedItem.value.leadCampaignDescription,
                          campaignAltCode: selectedItem.value.ihCampaignCode,
                          campaignAltDescription: selectedItem.value.ihCampaignDescription
                        });
                      }}
                    />
                  </div>
                }
                <div className="form-group">
                  <label className="form-label">Campaign Code</label>
                  <input
                    disabled={eventData?.campaignCodeVerified ? true : false}
                    type="text"
                    id="campaignCode"
                    name="campaignCode"
                    value={eventData?.campaignCode || ''}
                    onChange={handleChange}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Campaign Description</label>
                  <input
                    disabled={eventData?.campaignCodeVerified ? true : false}
                    type="text"
                    id="campaignDescription"
                    name="campaignDescription"
                    value={eventData?.campaignDescription || ''}
                    onChange={handleChange}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Campaign Interaction History Code</label>
                  <input
                    disabled={eventData?.campaignCodeVerified ? true : false}
                    type="text"
                    id="campaignAltCode"
                    name="campaignAltCode"
                    value={eventData?.campaignAltCode || ''}
                    onChange={handleChange}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Campaign Interaction History Description</label>
                  <input
                    disabled={eventData?.campaignCodeVerified ? true : false}
                    type="text"
                    id="campaignAltDescription"
                    name="campaignAltDescription"
                    value={eventData?.campaignAltDescription || ''}
                    onChange={handleChange}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Campaign Comments</label>
                  <input
                    disabled={eventData?.campaignCodeVerified ? true : false}
                    type="text"
                    id="campaignComments"
                    name="campaignComments"
                    value={eventData?.campaignComments || ''}
                    onChange={handleChange}
                    className={"form-control"}
                  />
                </div>
                <div
                  className=
                  "row align-items-center"
                >
                  <div className="col">
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Process leads for all checked-in/completed registrations
                        <input
                          // disabled={eventData?.pushToCapCompleted}
                          type="checkbox"
                          onChange={(e) => {
                            if (user.email.indexOf("core12.com") > -1) {
                              setEventData({
                                ...eventData,
                                checkedInLeads: e.target.checked,
                              });
                            } else {
                              alert('Contact a Core12 Admin to enable this feature');
                            }
                          }}
                          id="checkedInLeads"
                          name="checkedInLeads"
                          checked={eventData?.checkedInLeads || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Skip contact by dealer opt-in
                        <input
                          // disabled={eventData?.pushToCapCompleted}
                          type="checkbox"
                          onChange={(e) => {
                            if (user.email.indexOf("core12.com") > -1) {
                              setEventData({
                                ...eventData,
                                skipDealerOptIn: e.target.checked,
                              });
                            } else {
                              alert('Contact a Core12 Admin to enable this feature');
                            }
                          }}
                          id="skipDealerOptIn"
                          name="skipDealerOptIn"
                          checked={eventData?.skipDealerOptIn || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Process leads at end of event.
                        <input
                          // disabled={eventData?.pushToCapCompleted}
                          type="checkbox"
                          onChange={(e) => {
                            if (user.email.indexOf("core12.com") > -1) {
                              setEventData({
                                ...eventData,
                                endOfEvent: e.target.checked,
                              });
                            } else {
                              alert('Contact a Core12 Admin to enable this feature');
                            }
                          }}
                          id="endOfEvent"
                          name="endOfEvent"
                          checked={eventData?.endOfEvent || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Approved by Stakeholder & CRM
                        <input
                          // disabled={eventData?.pushToCapCompleted}
                          type="checkbox"
                          onChange={(e) => {
                            if (user.email.indexOf("core12.com") > -1) {
                              setEventData({
                                ...eventData,
                                campaignCodeVerified: e.target.checked,
                              });
                            } else {
                              alert('Contact a Core12 Admin to enable this feature');
                            }
                          }}
                          id="campaignCodeVerified"
                          name="campaignCodeVerified"
                          checked={eventData?.campaignCodeVerified || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        }
      </div>
      <div className={!eventData?.disableRegistration ? "card mb-4" : "d-none"}>
        <div className="card-header" onClick={() => { updateShowModules('fields', !showModules.fields) }}>
          <h2>Data Fields</h2>
          <div>
            <span onClick={() => { updateShowModules('fields', true) }}
              className={showModules.fields ? "active pe-3" : "pe-3"}>Show</span>
            <span onClick={() => { updateShowModules('fields', false) }}
              className={!showModules.fields ? "active" : ""}>Hide</span>
          </div>
        </div>
        {showModules.fields &&
          <div className="card-body">
            <h3>CRM Data Fields</h3>
            <div className="row">
              <div className="col">Company </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.company?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            company: {
                              ...eventData?.dataFields?.company,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="company.required"
                      name="company.required"
                      checked={eventData?.dataFields?.company?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.company?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              company: {
                                ...eventData?.dataFields?.company,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="company.adminOnly"
                        name="company.adminOnly"
                        checked={eventData?.dataFields?.company?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            company: {
                              ...eventData?.dataFields?.company,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="company.visible"
                      name="company.visible"
                      checked={eventData?.dataFields?.company?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col">Title </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.title?.visible ? "form-group" : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            title: {
                              ...eventData?.dataFields?.title,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="title.required"
                      name="title.required"
                      checked={eventData?.dataFields?.title?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.title?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              title: {
                                ...eventData?.dataFields?.title,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="title.adminOnly"
                        name="title.adminOnly"
                        checked={eventData?.dataFields?.title?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            title: {
                              ...eventData?.dataFields?.title,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="title.visible"
                      name="title.visible"
                      checked={eventData?.dataFields?.title?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">First Name </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.firstName?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            firstName: {
                              ...eventData?.dataFields?.firstName,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="firstName.required"
                      name="firstName.required"
                      checked={eventData?.dataFields?.firstName?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.firstName?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              firstName: {
                                ...eventData?.dataFields?.firstName,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="firstName.adminOnly"
                        name="firstName.adminOnly"
                        checked={eventData?.dataFields?.firstName?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            firstName: {
                              ...eventData?.dataFields?.firstName,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="firstName.visible"
                      name="firstName.visible"
                      checked={eventData?.dataFields?.firstName?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Last Name </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.lastName?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            lastName: {
                              ...eventData?.dataFields?.lastName,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="lastName.required"
                      name="lastName.required"
                      checked={eventData?.dataFields?.lastName?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.lastName?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              lastName: {
                                ...eventData?.dataFields?.lastName,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="lastName.adminOnly"
                        name="lastName.adminOnly"
                        checked={eventData?.dataFields?.lastName?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            lastName: {
                              ...eventData?.dataFields?.lastName,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="lastName.visible"
                      name="lastName.visible"
                      checked={eventData?.dataFields?.lastName?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Email Address </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.email?.visible ? "form-group" : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            email: {
                              ...eventData?.dataFields?.email,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="email.required"
                      name="email.required"
                      checked={eventData?.dataFields?.email?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.email?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              email: {
                                ...eventData?.dataFields?.email,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="email.adminOnly"
                        name="email.adminOnly"
                        checked={eventData?.dataFields?.email?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            email: {
                              ...eventData?.dataFields?.email,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="email.visible"
                      name="email.visible"
                      checked={eventData?.dataFields?.email?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Phone Number </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.phone?.visible ? "form-group" : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            phone: {
                              ...eventData?.dataFields?.phone,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="phone.required"
                      name="phone.required"
                      checked={eventData?.dataFields?.phone?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.phone?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              phone: {
                                ...eventData?.dataFields?.phone,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="phone.adminOnly"
                        name="phone.adminOnly"
                        checked={eventData?.dataFields?.phone?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            phone: {
                              ...eventData?.dataFields?.phone,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="phone.visible"
                      name="phone.visible"
                      checked={eventData?.dataFields?.phone?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Address 1 </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.address1?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            address1: {
                              ...eventData?.dataFields?.address1,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="address1.required"
                      name="address1.required"
                      checked={eventData?.dataFields?.address1?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.address1?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              address1: {
                                ...eventData?.dataFields?.address1,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="address1.adminOnly"
                        name="address1.adminOnly"
                        checked={eventData?.dataFields?.address1?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            address1: {
                              ...eventData?.dataFields?.address1,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="address1.visible"
                      name="address1.visible"
                      checked={eventData?.dataFields?.address1?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Address 2 </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.address2?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            address2: {
                              ...eventData?.dataFields?.address2,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="address2.required"
                      name="address2.required"
                      checked={eventData?.dataFields?.address2?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.address2?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              address2: {
                                ...eventData?.dataFields?.address2,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="address2.adminOnly"
                        name="address2.adminOnly"
                        checked={eventData?.dataFields?.address2?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            address2: {
                              ...eventData?.dataFields?.address2,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="address2.visible"
                      name="address2.visible"
                      checked={eventData?.dataFields?.address2?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">City</div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.city?.visible ? "form-group" : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            city: {
                              ...eventData?.dataFields?.city,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="city.required"
                      name="city.required"
                      checked={eventData?.dataFields?.city?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.city?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              city: {
                                ...eventData?.dataFields?.city,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="city.adminOnly"
                        name="city.adminOnly"
                        checked={eventData?.dataFields?.city?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            city: {
                              ...eventData?.dataFields?.city,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="city.visible"
                      name="cityvisible"
                      checked={eventData?.dataFields?.city?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">State </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.state?.visible ? "form-group" : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            state: {
                              ...eventData?.dataFields?.state,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="state.required"
                      name="state.required"
                      checked={eventData?.dataFields?.state?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.state?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              state: {
                                ...eventData?.dataFields?.state,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="state.adminOnly"
                        name="state.adminOnly"
                        checked={eventData?.dataFields?.state?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            state: {
                              ...eventData?.dataFields?.state,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="state.visible"
                      name="state.visible"
                      checked={eventData?.dataFields?.state?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Postal Code </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.postal?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            postal: {
                              ...eventData?.dataFields?.postal,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="postal.required"
                      name="postal.required"
                      checked={eventData?.dataFields?.postal?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.postal?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              postal: {
                                ...eventData?.dataFields?.postal,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="postal.adminOnly"
                        name="postal.adminOnly"
                        checked={eventData?.dataFields?.postal?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            postal: {
                              ...eventData?.dataFields?.postal,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="postal.visible"
                      name="postal.visible"
                      checked={eventData?.dataFields?.postal?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Porsche Experience </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.experience?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            experience: {
                              ...eventData?.dataFields?.experience,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="experience.required"
                      name="experience.required"
                      checked={eventData?.dataFields?.experience?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.experience?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              experience: {
                                ...eventData?.dataFields?.experience,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="experience.adminOnly"
                        name="experience.adminOnly"
                        checked={eventData?.dataFields?.experience?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            experience: {
                              ...eventData?.dataFields?.experience,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="experience.visible"
                      name="experience.visible"
                      checked={eventData?.dataFields?.experience?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Purchase Timeframe </div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.purchaseTimeFrame?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            purchaseTimeFrame: {
                              ...eventData?.dataFields?.purchaseTimeFrame,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="purchaseTimeFrame.required"
                      name="purchaseTimeFrame.required"
                      checked={eventData?.dataFields?.purchaseTimeFrame?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.purchaseTimeFrame?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              purchaseTimeFrame: {
                                ...eventData?.dataFields?.purchaseTimeFrame,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="purchaseTimeFrame.adminOnly"
                        name="purchaseTimeFrame.adminOnly"
                        checked={eventData?.dataFields?.purchaseTimeFrame?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            purchaseTimeFrame: {
                              ...eventData?.dataFields?.purchaseTimeFrame,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="purchaseTimeFrame.visible"
                      name="purchaseTimeFrame.visible"
                      checked={eventData?.dataFields?.purchaseTimeFrame?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Model Interest</div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.modelInterest?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            modelInterest: {
                              ...eventData?.dataFields?.modelInterest,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="modelInterest.required"
                      name="modelInterest.required"
                      checked={eventData?.dataFields?.modelInterest?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.modelInterest?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              modelInterest: {
                                ...eventData?.dataFields?.modelInterest,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="modelInterest.adminOnly"
                        name="modelInterest.adminOnly"
                        checked={eventData?.dataFields?.modelInterest?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            modelInterest: {
                              ...eventData?.dataFields?.modelInterest,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="modelInterest.visible"
                      name="modelInterest.visible"
                      checked={eventData?.dataFields?.modelInterest?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">Dealer</div>
              <div className="col-auto">
                <div
                  className={
                    eventData?.dataFields?.dealer?.visible
                      ? "form-group"
                      : "d-none"
                  }
                >
                  <label className="checkbox-container">
                    Required
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            dealer: {
                              ...eventData?.dataFields?.dealer,
                              required: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="dealer.required"
                      name="dealer.required"
                      checked={eventData?.dataFields?.dealer?.required || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {!eventData?.dataFields?.dealer?.visible &&
                <div className="col-auto">
                  <div className={"form-group"}>
                    <label className="checkbox-container">
                      Admin Visible
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            dataFields: {
                              ...eventData?.dataFields,
                              dealer: {
                                ...eventData?.dataFields?.dealer,
                                adminOnly: e.target.checked,
                              },
                            },
                          });
                        }}
                        id="dealer.adminOnly"
                        name="dealer.adminOnly"
                        checked={eventData?.dataFields?.dealer?.adminOnly || false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
              <div className="col-auto">
                <div className={"form-group"}>
                  <label className="checkbox-container">
                    Visible
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          dataFields: {
                            ...eventData?.dataFields,
                            dealer: {
                              ...eventData?.dataFields?.dealer,
                              visible: e.target.checked,
                            },
                          },
                        });
                      }}
                      id="dealer.visible"
                      name="dealer.visible"
                      checked={eventData?.dataFields?.dealer?.visible || false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                (eventData?.dataFields?.dealer?.visible || eventData?.dataFields?.dealer?.adminOnly) ? "form-group" : "d-none"
              }
            >
              <div>Choose which dealers to show in the dropdown.  Leave empty to show all dealers.</div>
              <Multiselect
                options={dealers}
                style={multiSelectStyle}
                displayValue="name"
                ref={dealerSelectRef}
                selectedValues={eventData?.dealersSelected}
                closeIcon="close"
                onSelect={() => {
                  console.log(dealerSelectRef.current.getSelectedItems());
                  setEventData({
                    ...eventData,
                    dealersSelected: dealerSelectRef.current.getSelectedItems(),
                  });
                }}
                onRemove={() => {
                  console.log(dealerSelectRef.current.getSelectedItems());
                  setEventData({
                    ...eventData,
                    dealersSelected: dealerSelectRef.current.getSelectedItems(),
                  });
                }}
              />
            </div>
            {/* <div
                className={
                  eventData?.dataFields?.modelInterest?.visible
                    ? "form-group"
                    : "d-none"
                }
              >
                <Multiselect
                  options={models}
                  style={multiSelectStyle}
                  displayValue="description"
                  selectedValues={eventData?.modelsSelected}
                  closeIcon="close"
                  ref={modelSelectRef}
                  onSelect={() => {
                    setEventData({
                      ...eventData,
                      modelsSelected: modelSelectRef.current.getSelectedItems(),
                    });
                  }}
                  onRemove={() => {
                    setEventData({
                      ...eventData,
                      modelsSelected: modelSelectRef.current.getSelectedItems(),
                    });
                  }}
                />
              </div> */}
            <h3>Additional Data Fields</h3>
            <div className="text-end">
              <button
                className={"btn btn-theme"}
                onClick={() => {
                  if (eventData?.additionalFields) {
                    setEventData({
                      ...eventData,
                      additionalFields: [...eventData?.additionalFields, {}],
                    });
                  } else {
                    setEventData({
                      ...eventData,
                      additionalFields: [],
                    });
                  }
                }}
              >
                + DATA FIELD
              </button>
            </div>
            <div className="py-3"></div>
            {eventData?.additionalFields?.map((cta, index) => (
              <div key={index} className={index % 2 == 0 ? "alt-row cta-list-item" : "cta-list-item"}>
                <div className="form-group">
                  <label className="form-label">Data Field Type</label>
                  <select
                    className="form-control"
                    id={"fieldType" + index}
                    name="fieldType"
                    onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                    value={eventData?.additionalFields[index].fieldType || ''}
                  >
                    <option value="">Blank</option>
                    <option value="Textblock">Textblock</option>
                    <option value="Textbox">Textbox</option>
                    <option value="Numberbox">Numberbox</option>
                    <option value="CommerceItem">Commerce Item</option>
                    {/* {arraySearch(eventData?.additionalFields,'CommerceItem',index) ? 
                      <option value="CommerceItem">Commerce Item</option>
                      :<></>
                    } */}
                    <option value="Dropdown">Dropdown</option>
                    <option value="Checkbox">Checkbox</option>
                    <option value="DateTime">Date and Time</option>
                    <option value="Date">Date</option>
                    <option value="Time">Time</option>
                  </select>
                </div>
                {eventData?.additionalFields[index].fieldType ? (<>
                  {eventData?.additionalFields[index].fieldType != "Textblock" ? (<>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldLabel: !help.fieldLabel });
                        }}
                      >
                        Field Label #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldLabel ? "fs-xs" : "d-none"}>
                        Text label for field #{index + 1}.
                      </div>
                      <input
                        type="text"
                        id={"fieldLabel" + index}
                        name="fieldLabel"
                        value={eventData?.additionalFields[index].fieldLabel || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldDescription: !help.fieldDescription });
                        }}
                      >
                        Field Description #{index + 1} {" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldDescription ? "fs-xs" : "d-none"}>
                        Description for Field #{index + 1}.
                      </div>
                      <input
                        type="text"
                        id={"fieldDescription" + index}
                        name="fieldDescription"
                        value={eventData?.additionalFields[index].fieldDescription || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldName: !help.fieldName });
                        }}
                      >
                        Field Name #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldName ? "fs-xs" : "d-none"}>
                        Unique name for field #{index + 1}.  No special charactuers, numbers or spaces.
                      </div>
                      <input
                        type="text"
                        id={"fieldName" + index}
                        name="fieldName"
                        value={eventData?.additionalFields[index].fieldName || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                  </>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType == "Textblock" ? (
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, textCopy: !help.textCopy });
                        }}
                      >
                        Text Copy #{index + 1} {" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.textCopy ? "fs-xs" : "d-none"}>
                        A block of text inline in the form.
                      </div>
                      <textarea
                        id={"textCopy" + index}
                        name="textCopy"
                        value={eventData?.additionalFields[index].textCopy || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      ></textarea>
                    </div>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType == "Time" || eventData?.additionalFields[index].fieldType == "DateTime" || eventData?.additionalFields[index].fieldType == "Date" ? (<>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMin: !help.fieldMin });
                        }}
                      >
                        Min #{index + 1} {" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.name ? "fs-xs" : "d-none"}>
                        Min for Field #{index + 1} (semi-colon separated list).
                      </div>
                      <input
                        type={eventData?.additionalFields[index].fieldType == "Date" ? "date" : eventData?.additionalFields[index].fieldType == "Time" ? "time" : "datetime-local"}
                        id={"fieldMin" + index}
                        name="fieldMin"
                        value={eventData?.additionalFields[index].fieldMin || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMax: !help.fieldMax });
                        }}
                      >
                        Max #{index + 1} {" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.name ? "fs-xs" : "d-none"}>
                        Max for Field #{index + 1} (semi-colon separated list).
                      </div>
                      <input
                        type={eventData?.additionalFields[index].fieldType == "Date" ? "date" : eventData?.additionalFields[index].fieldType == "Time" ? "time" : "datetime-local"}
                        id={"fieldMax" + index}
                        name="fieldMax"
                        value={eventData?.additionalFields[index].fieldMax || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                  </>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType == "Dropdown" ? (<>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldOptions: !help.fieldOptions });
                        }}
                      >
                        Field Options #{index + 1} {" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldOptions ? "fs-xs" : "d-none"}>
                        Options for Field #{index + 1} (semi-colon separated list).
                      </div>
                      <input
                        type="text"
                        id={"fieldOptions" + index}
                        name="fieldOptions"
                        value={eventData?.additionalFields[index].fieldOptions || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                  </>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType === "CommerceItem" ? (<>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMin: !help.fieldMin });
                        }}
                      >
                        Field Min #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldMin ? "fs-xs" : "d-none"}>
                        Min value for number field #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldMin" + index}
                        name="fieldMin"
                        value={eventData?.additionalFields[index].fieldMin || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMax: !help.fieldMax });
                        }}
                      >
                        Field Max #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldMax ? "fs-xs" : "d-none"}>
                        Max value for number field #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldMax" + index}
                        name="fieldMax"
                        value={eventData?.additionalFields[index].fieldMax || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldStep: !help.fieldStep });
                        }}
                      >
                        Field Cost #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldStep ? "fs-xs" : "d-none"}>
                        Cost value for item #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldCost" + index}
                        name="fieldCost"
                        step="any"
                        value={eventData?.additionalFields[index].fieldCost || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Field Capacity Type #{index + 1}{" "}</label>
                      <select
                        className="form-control"
                        id={"fieldCapacity" + index}
                        name="fieldCapacity"
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        value={eventData?.additionalFields[index].fieldCapacity || ''}
                      >
                        <option value="">No Capacity</option>
                        <option value="eventCapacity">Event Capacity</option>
                        <option value="itemCapacity">Item Capacity</option>
                      </select>
                    </div>
                    {eventData?.additionalFields[index].fieldCapacity === "itemCapacity" ? (
                      <div className={"form-group"}>
                        <label
                          className="form-label form-help"
                          onClick={() => {
                            setHelp({ ...help, fieldCapacityNum: !help.fieldCapacityNum });
                          }}
                        >
                          Field Capacity #{index + 1}{" "}
                          <i className="xs">
                            <InfoIcon />
                          </i>
                        </label>
                        <div className={help.fieldCapacityNum ? "fs-xs" : "d-none"}>
                          Max value for capacity field #{index + 1}.
                        </div>
                        <input
                          type="number"
                          id={"fieldCapacityNum" + index}
                          name="fieldCapacityNum"
                          value={eventData?.additionalFields[index].fieldCapacityNum || ''}
                          onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                          className="form-control"
                        />
                      </div>
                    ) : (<></>)}
                  </>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType == "Numberbox" ? (<>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMin: !help.fieldMin });
                        }}
                      >
                        Field Min #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldMin ? "fs-xs" : "d-none"}>
                        Min value for number field #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldMin" + index}
                        name="fieldMin"
                        value={eventData?.additionalFields[index].fieldMin || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldMax: !help.fieldMax });
                        }}
                      >
                        Field Max #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldMax ? "fs-xs" : "d-none"}>
                        Max value for number field #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldMax" + index}
                        name="fieldMax"
                        value={eventData?.additionalFields[index].fieldMax || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                    <div className={"form-group"}>
                      <label
                        className="form-label form-help"
                        onClick={() => {
                          setHelp({ ...help, fieldStep: !help.fieldStep });
                        }}
                      >
                        Field Step #{index + 1}{" "}
                        <i className="xs">
                          <InfoIcon />
                        </i>
                      </label>
                      <div className={help.fieldStep ? "fs-xs" : "d-none"}>
                        Step value for number field #{index + 1}.
                      </div>
                      <input
                        type="number"
                        id={"fieldStep" + index}
                        name="fieldStep"
                        value={eventData?.additionalFields[index].fieldStep || ''}
                        onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                        className="form-control"
                      />
                    </div>
                  </>
                  ) : (<div></div>)}
                  {eventData?.additionalFields[index].fieldType != "Textblock" ? (<>
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Field Required
                        <input
                          type="checkbox"
                          onChange={(e) => { handleListChange(e, index, 'additionalFields', true) }}
                          id={"required" + index}
                          name="required"
                          checked={eventData?.additionalFields[index].required || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Field Full Row
                        <input
                          type="checkbox"
                          onChange={(e) => { handleListChange(e, index, 'additionalFields', true) }}
                          id={"fieldFullRow" + index}
                          name="fieldFullRow"
                          checked={eventData?.additionalFields[index].fieldFullRow || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {!eventData?.additionalFields[index].fieldFullRow &&
                      <>
                        <div className="form-group">
                          <label className="form-label">Field Number of Columns</label>
                          <select
                            className="form-control"
                            id={"fieldColumns" + index}
                            name="fieldColumns"
                            onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                            value={eventData?.additionalFields[index].fieldColumns || ''}
                          >
                            <option value="12">1</option>
                            <option value="6">2</option>
                            <option value="4">3</option>
                            <option value="3">4</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Field Number of Columns Mobile</label>
                          <select
                            className="form-control"
                            id={"fieldColumnsMobile" + index}
                            name="fieldColumnsMobile"
                            onChange={(e) => { handleListChange(e, index, 'additionalFields') }}
                            value={eventData?.additionalFields[index].fieldColumnsMobile || ''}
                          >
                            <option value="12">1</option>
                            <option value="6">2</option>
                            <option value="4">3</option>
                            <option value="3">4</option>
                          </select>
                        </div>
                      </>
                    }
                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        Field Manage Only
                        <input
                          type="checkbox"
                          onChange={(e) => { handleListChange(e, index, 'additionalFields', true) }}
                          id={"fieldAdminOnly" + index}
                          name="fieldAdminOnly"
                          checked={eventData?.additionalFields[index].fieldAdminOnly || false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </>
                  ) : (<div></div>)}
                </>
                ) : (<div></div>)}
                <div className="text-end">
                  <i className="del-icon ml-2" onClick={(e) => {
                    let additionalFields = eventData?.additionalFields;
                    additionalFields.splice(index, 1);
                    setEventData({ ...eventData, additionalFields: additionalFields });
                  }}>
                    <DeleteIcon />
                  </i>
                </div>
              </div>
            ))}
            <div className="py-2"></div>
            <div className={"form-group"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, paymentTaxPercent: !help.paymentTaxPercent });
                }}
              >
                Payment Tax %
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.paymentTaxPercent ? "fs-xs" : "d-none"}>
                Tax % for Payment.
              </div>
              <input
                type="number"
                id="paymentTax"
                name="paymentTax"
                step="any"
                value={eventData?.paymentTax || 0}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, paymentType: !help.paymentType });
                }}
              >
                Payment Type
                <i className="xs">
                  <InfoIcon />
                </i>
                <div className={help.paymentType ? "fs-xs" : "d-none"}>
                  Tax % for Payment.
                </div>
              </label>
              <select
                className="form-control"
                id="paymentType"
                name="paymentType"
                value={eventData?.paymentType || ''}
                onChange={handleChange}
              >
                <option value="">No Payments</option>
                <option value="payment">Inline Payment</option>
                <option value="paymentLink">Payment Link</option>
              </select>
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Production Payment
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (user.email.indexOf("core12.com") > -1) {
                      setEventData({
                        ...eventData,
                        prodPayment: e.target.checked,
                      });
                    } else {
                      alert("Contact a Core12 Admin to update this feature.");
                    }
                  }}
                  id="prodPayment"
                  name="prodPayment"
                  checked={eventData?.prodPayment || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="py-2"></div>
            <div className="form-group">
              <label className="checkbox-container">
                Allow upload submission
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      allowUpload: e.target.checked,
                    });
                  }}
                  id="allowUpload"
                  name="allowUpload"
                  checked={eventData?.allowUpload || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={eventData?.allowUpload ? "form-group" : "d-none"}>
              <label className="checkbox-container">
                Upload submission required
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      uploadRequired: e.target.checked,
                    });
                  }}
                  id="uploadRequired"
                  name="uploadRequired"
                  checked={eventData?.uploadRequired || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={eventData?.allowUpload ? "form-group" : "d-none"}>
              <label className="form-label">Upload Copy</label>
              <textarea
                id="uploadCopy"
                name="uploadCopy"
                value={eventData?.uploadCopy}
                onChange={handleChange}
                className="form-control"
              ></textarea>
            </div>
            <div className={eventData?.allowUpload ? "form-group" : "d-none"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, uploadMaxFiles: !help.uploadMaxFiles });
                }}
              >
                Max files to upload {" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.submitButton ? "fs-xs" : "d-none"}>
                Maximum number of files to upload with the submission.
              </div>
              <input
                type="number"
                id="uploadMaxFiles"
                name="uploadMaxFiles"
                value={eventData?.uploadMaxFiles || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={eventData?.allowUpload ? "form-group" : "d-none"}>
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, uploadMinFiles: !help.uploadMinFiles });
                }}
              >
                Min files to upload {" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.submitButton ? "fs-xs" : "d-none"}>
                Minimum number of files to upload with the submission.
              </div>
              <input
                type="number"
                id="uploadMinFiles"
                name="uploadMinFiles"
                value={eventData?.uploadMinFiles || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label
                className="form-label form-help"
                onClick={() => {
                  setHelp({ ...help, submitButton: !help.submitButton });
                }}
              >
                Alternate Submit Button{" "}
                <i className="xs">
                  <InfoIcon />
                </i>
              </label>
              <div className={help.submitButton ? "fs-xs" : "d-none"}>
                Alternate Submit button name.  Default Submit if left blank.
              </div>
              <input
                type="text"
                id="submitButton"
                name="submitButton"
                value={eventData?.submitButton || ''}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className={"form-group"}>
              <label className="checkbox-container">
                Hide Enter Details Title
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      hideEnterDetails: e.target.checked,
                    });
                  }}
                  id="hideEnterDetails"
                  name="hideEnterDetails"
                  checked={eventData?.hideEnterDetails || false}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        }
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header">
              <h2>Event History</h2>
            </div>
            <div className="card-body">
              <div><label>Created:</label>{eventData?.createdAt ? moment(eventData?.createdAt).format("MM/DD/YYYY h:mm A") : "N/A"}</div>
              <div><label>Updated:</label> {eventData?.updatedAt ? moment(eventData?.updatedAt).format("MM/DD/YYYY h:mm A") : "N/A"}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3"></div>
      <div className={floatingSave ? "floating-save" : "pb-4"}>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button onClick={() => {
              setCurrentEventData({});
              sessionStorage.removeItem('eventData');
              setEventData({});
              setTimeout(() => {
                window.location.href = "/manage";
              }, 20)
            }}
              className="btn btn-default">Close</button>
          </div>
          <div className="col text-end">
          </div>
        </div>
      </div>
      <div className="py-3"></div>
      <div className="py-3"></div>
      <div className="modal fade" id="visual-editor" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" onClick={() => { updateShowModules('design', !showModules.design) }}>
              <h6 className="modal-title"></h6>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body">
              <VisualEditor idkey={editCopy} value={eventData} changeItem={handleChangeItem} />
            </div>
          </div>
        </div>
      </div>
      <Modal enforceFocus={false} show={editCopy == null ? false : true} onHide={() => { setEditCopy(null) }}>
        <Modal.Header closeButton>
          <Modal.Title>Visual Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fieldList.length > 0 && editCopy?.toLowerCase().indexOf('email') >= 0 &&
            <div>
              <label>Available Fields:</label><br />
              {fieldList.map((g, index) => (
                <span>{g}{index < fieldList.length - 1 ? ", " : ""}</span>
              ))}
              <br /><br />
            </div>
          }
          <VisualEditor idkey={editCopy} value={eventData} changeItem={handleChangeItem} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-theme" onClick={() => { setEditCopy(null); }}>Update</button>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Event;
